// Component Name : InfoCard
// C1 CMS Name : LinkBox

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import InfoIcon from './CardContent/info';

// Component
const InfoCard = (props) => {
  const {
    image,
    category,
    text,
    url,
    imageAltText,
  } = props;
  return (
    <Card shadow maxWidth="100%" minHeight="100%" url={url}>
      <InfoIcon group={category} text={text} arrow image={image} altText={imageAltText} />
    </Card>
  );
};

// Proptypes
InfoCard.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
};

InfoCard.defaultProps = {
  image: '',
  imageAltText: '',
};

export default InfoCard;
