// Import Node Modules
import styled from 'styled-components';
import { Link } from 'gatsby';

// Import Guides
import * as Variables from '../Variables';
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { media } from '../../../../cssGuides/MediaQuerys';

// Components
export const SideNav = styled.div`
  display:block;
  ${media.tabletP`
    display:none;
  `};

  height: calc(100% - 53px);
  width: ${({ open }) => (open ? 100 : 0)}%;
  position: fixed;
  z-index: 100;
  top: ${Variables.level1 + 3}px;
  right: 0;
  background-color: ${props => props.color};
  overflow-x: hidden;
  transition: 0.5s;
  text-align: left;
  button,
  a {
    text-align: left;
    font-size: 20px;
    display: block;
    transition: 0.3s;
    width: 100%;
    color: white;
    &:hover {
      color: ${ColorGuide.white};
    }
    &:visited {
      color: white;
    }
  }
`;

export const MobileMenuItem = styled.div`
  color: ${ColorGuide.white};
  padding: 5px auto;
  text-align: center;
  white-space: nowrap;
  ${props => (props.item === props.test ? '' : 'background-color:white;')};
  ${props => (props.item === props.test ? '' : 'color:black;')};

  font-size: 12px;
`;

export const TabMenuItem = styled.div`
  height: ${Variables.level1}px;
  line-height: ${Variables.level1}px;
  font-size: 12px;
`;

export const SideNavContent = styled.div`
  padding: 5px 0;
`;

export const ArrowIcon = styled.img.attrs({
  alt: 'Arrow',
})`
  float: right;
  margin-top: 5px;
`;

export const SideBarItem = styled.div`
  border-bottom: 1px solid ${ColorGuide.lightGray};
  padding: 12px 20px;
  button {
    background-color:transparent;
    border:none;
  }
`;

export const BottomLinks = styled(Link)`
  background-color: ${ColorGuide.darkGray};
  font-size: 14px !important;
  padding: 8px 20px;
`;

export const ExLinks = styled.a`
  background-color: ${ColorGuide.darkGray};
  font-size: 14px !important;
  padding: 8px 20px;
`;

export const BackBtn = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  padding: 30px 20px;
  color: ${ColorGuide.darkGray};
  white-space: nowrap;
`;

export const ArrowIconLeft = styled.img.attrs({
  alt: 'Arrow Left',
})`
  position: relative;
  top: 2px;
  margin-right: 10px;
  transform: scaleX(-1);
`;

export const MobileMegaMenuItem = styled(Link)`
  color: ${ColorGuide.white} !important;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  align-self: center;
  padding: 12px 20px;
  border-top: 1px solid ${ColorGuide.aquaMedium};

  &:last-child {
    border-bottom: 1px solid ${ColorGuide.aquaMedium};
  }

  &:hover {
    color: ${ColorGuide.lightGray} !important;
  }
`;
