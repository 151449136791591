// Import Node Modules
import styled from 'styled-components';
import { Link } from 'gatsby';

// Import Guides
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { media } from '../../../../cssGuides/MediaQuerys';

// Styled components

export const Overlay = styled.div.attrs({
  role: 'none',
})`
  display: none;
  width: 100%;
  max-width: 1560px;
  height: 1500px;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

export const MegaMenuContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: ${ColorGuide.PUBLIC_MENU_DROPDOWN};
  padding: 30px 0;
  position: relative;
  top: -8px;
`;

export const MegaMenuItem = styled(Link)`
  color: ${ColorGuide.white} !important;
  font-weight: 400;
  cursor: pointer;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 80px;
  display: flex;

  height: 50px;

  align-items: center;
  &:hover {
    color: ${ColorGuide.lightGray} !important;
  }

  ${media.tabletP`
    margin-left: 15px;
    font-size: 11px;

  `};
  ${media.tabletL`
    margin-left: 80px;
    font-size: 12px;

  `};
  ${media.desktop`
    font-size: 14px;
  `};
`;

export const MegaMenuItemText = styled.span`
  padding-left: 10px;
  white-space: break-spaces;
`;
