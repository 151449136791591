// Import ColorGuide
// import * as ColorGuide from '../cssGuides/colorGuide';

// Import Logos
import Logo from '../assets/Logo/PJD2.svg';
import SquareLogo from '../assets/OGData/PJD_logo.jpg';

export default {
  // Firma Domaine
  Domaine: 'pjdpension.dk',
  Origin: 'https://classic.pjdpension.dk',
  logoutUrl: 'https://classic.pjdpension.dk/pjd/log-ind/din-pension/Log-ud',
  homeUrl: 'https://classic.pjdpension.dk/pjd/log-ind/din-pension',
  profileUrl: 'https://classic.pjdpension.dk/pjd/log-ind/din-pension/Dine-tal/din-profil/Din-e-mail11',
  // Primary Web Color
  PrimaryColor: '#169b62',
  PrimaryHover: '#4291b8',
  PrimaryVisited: '#4f4586',
  // Logos
  DesktopLogo: Logo,
  MobileLogo: Logo,
  SquareLogo,
  // Logo Sizes
  LogoSizeMobile: '25px',
  LogoSizeTabletP: '22px',
  LogoSizeTabletL: '23px',
  LogoSizeDesktop: '38px',
  // Logo left padding
  LogoLeftMobile: '10px',
  LogoLeftTabletP: '23px',
  LogoLeftTabletL: '23px',
  LogoLeftDesktop: '23px',
  // Logo container size
  LogoContainerSize: '300px',
  // MainMenu ON/OFF
  MainMenuSeperator: true,
  // Footer Links
  FooterLinks: true,
  // Button Name (Login)
  BtnName: 'Log ind',
  // Root folder
  StartUrl: '/medlem',
  // Mobile Table  Collapse
  MobileTable: '#bbdced',
  // Search enigne key
  EngineKey: 'yx-SF28JduxW1_hacbP-',
};
