// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Import Common Components
import { Columns } from '../../../CommonComponents';
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { Consumer as LoginContext } from '../../../../Context/loginContext';

// Import Styled-components
import {
  SideNav,
  MobileMenuItem,
  TabMenuItem,
  SideNavContent,
  BottomLinks,
  ExLinks,
  BackBtn,
  ArrowIcon,
  SideBarItem,
  ArrowIconLeft,
  MobileMegaMenuItem,
} from '../StyledComponents/Sidebar';

import SearchBar from './SearchBar';

// Import Icon
import BlueArrow from '../../../../assets/Icons/svg/icon_Arrow_Right_Blue.svg';
import BlackArrow from '../../../../assets/Icons/svg/icon_Arrow_Right_DarkGray.svg';

// Component Class SideBar
const SideBar = (props) => {
  const {
    open,
    ActiveMenu,
    menu,
    megaMenu,
    closeAll,
    closeNav,
    openNav,
    changeMobileMenu,

  } = props;

  const checkLink = (menus) => {
    return menus.internal !== true ? (
      <ExLinks
        key={menus.id}
        href={menus.url}
        style={{ fontSize: '14px' }}
        onClick={() => {
          closeAll();
        }}
      >
        {menus.name}
      </ExLinks>
    ) : (
      <BottomLinks
        key={menus.id}
        to={menus.url}
        onClick={() => {
          closeAll();
        }}
      >
        {menus.name}
      </BottomLinks>
    );
  };

  return (
    <div>
      <SideNav
        id="mySidenav"
        open={open.includes('mySidenav')}
        color={ColorGuide.darkGray}
      >
        <Columns columns={3} minWidth="300px">
          {menu.menus.subMenus.map((menus) => {
            return menus.display === 'Always' ? (
              <MobileMenuItem
                key={menus.id}
                onClick={() => changeMobileMenu(menus.id)}
                item={menus.id}
                test={ActiveMenu}
              >
                <TabMenuItem>{menus.name}</TabMenuItem>
              </MobileMenuItem>
            ) : (
              ''
            );
          })}
        </Columns>
        <SideNavContent>
          {menu.menus.subMenus.map((menus) => {
            return ActiveMenu === menus.id ? (
              <div key={menus.id}>
                <SideBarItem>
                  <Link
                    to={menus.url}
                    onClick={() => {
                      closeAll();
                    }}
                  >
                    {menus.name} forside
                    <ArrowIcon src={BlueArrow} />
                  </Link>
                </SideBarItem>
                {menus.menuItems.map(menuItems => (
                  <SideBarItem key={menuItems.id}>
                    <button
                      type="button"
                      onClick={() => openNav(`Sidenav_${menuItems.name}`)}
                    >
                      {menuItems.name}
                      <ArrowIcon src={BlueArrow} />
                    </button>
                  </SideBarItem>
                ))}
              </div>
            ) : (
              ''
            );
          })}
        </SideNavContent>

        <SearchBar path={menu.searchUrl} />
        <LoginContext>
          {({ isLoggedIn }) => (
            isLoggedIn
              ? (
                <BottomLinks
                  to={`/classic#path=/din-pension&cachebuster=${new Date().getTime()}`}
                  style={{ display: 'block' }}
                >
                  Dine tal
                </BottomLinks>
              )
              : (
                <div />
              )
          )}
        </LoginContext>
        {menu.menus.subMenus.map((menus) => {
          return menus.display !== 'Always' ? (
            checkLink(menus)
          ) : (
            ''
          );
        })}
      </SideNav>

      {megaMenu.megaMenu.map(megaMenuItem => (
        <SideNav
          key={megaMenuItem.id}
          id={`Sidenav_${megaMenuItem.id}`}
          open={open.includes(`Sidenav_${megaMenuItem.name}`)}
          color={ColorGuide.aqua}
        >
          <BackBtn
            onClick={() => closeNav(`Sidenav_${megaMenuItem.name}`)}
          >
            <ArrowIconLeft src={BlackArrow} />
            Tilbage
          </BackBtn>
          <div>
            {megaMenuItem.menu.map(ItemMenu => (
              <MobileMegaMenuItem
                key={ItemMenu.id}
                to={ItemMenu.url}
                onClick={() => {
                  closeAll();
                }}
              >
                {ItemMenu.name}
              </MobileMegaMenuItem>
            ))}
          </div>
        </SideNav>
      ))}
    </div>
  );
};

// Proptypes
SideBar.propTypes = {
  open: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  ActiveMenu: PropTypes.string.isRequired,
  menu: PropTypes.shape({
    searchUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    menus: PropTypes.shape({
      subMenus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        display: PropTypes.string,
        internal: PropTypes.bool,
        menuItems: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })),
      })).isRequired,
    }).isRequired,
  }).isRequired,
  megaMenu: PropTypes.shape({
    megaMenu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        menu: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })),
      }).isRequired,
    ).isRequired,
  }).isRequired,
  closeAll: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  openNav: PropTypes.func.isRequired,
  changeMobileMenu: PropTypes.func.isRequired,

};

export default SideBar;
