// Import Node Modules
import styled, { css } from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Styled CSS
const hightSize = css`
  height: auto;
  ${media.tabletL`
    height: 175px;
  `};
`;

// Styled-components

export const Container = styled.footer`
  background-color: ${ColorGuide.darkGray};
  color: ${ColorGuide.white};
  ${hightSize};
  padding: 10px 0;
  ${media.tabletL`
    padding: 0 0 0 50px;
  `};
  ${media.desktop`
    padding: 0 0 0 100px;
  `};
`;

export const ColDiv = styled.div`
  ${hightSize};
  width:100%;
`;

// Address
export const Address = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  ${media.tabletL`
    text-align:left;
  `};
`;

export const Info = styled.div`
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const Phone = styled.div`
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0 0 20px 0;
  font-size: 20px;
  ${media.tabletL`
    padding:0;
    text-align:left;
  `};
  ${media.desktop`
    font-size: 13px;
  `}

  img {
    padding-right: 10px;
    width: 24px;
    ${media.tabletL`
      width: 24px;
    `};
    ${media.desktop`
      width: 20px;
    `};
  }
`;

// Links

export const Urls = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
  border-top: 1px solid ${ColorGuide.lightGray};
  ${media.tabletL`
    text-align:left;
    font-size: 12px;
    border:none;
  `};
  ${media.desktop`
    font-size: 14px;
  `}

  a {
    font-size: 13px;
    font-weight: 400;
    color: ${ColorGuide.white};
    &:hover {
      color: ${ColorGuide.aquaMedium};
    }
    &:visited {
      color: ${ColorGuide.white};
      &:hover {
      color: ${ColorGuide.aquaMedium};
    }
    }
  }
`;

// Social

export const Social = styled.div`
  ${hightSize};
  max-width: 100%;
  min-height: 50px; 
`;
