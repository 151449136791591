// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import {
  AlignContentCenter,
  Url,
} from '../../CommonComponents/index';

import BannerOverlayText from './overlay';

import { Header, Manchet, ReadMoreStyled } from './styledcomponents';

import { isMobileOnClient } from '../../../template/helperFunctions';

// Class
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      desktop: !isMobileOnClient(),
    };
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleNavResize);
    this.handleNavResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      desktop: !isMobileOnClient(),
    });
  }

  renderContent() {
    const {
      header,
      manchet,
      urlText,
      fullWidth,
    } = this.props;

    return (
      <AlignContentCenter>
        <Header
          dangerouslySetInnerHTML={{ __html: header }}
          fullSize={fullWidth}
        />

        <Manchet
          className="intro"
          dangerouslySetInnerHTML={{ __html: manchet }}
        />

        <ReadMoreStyled text={urlText} fullSize={fullWidth} />
      </AlignContentCenter>
    );
  }

  render() {
    const {
      image,
      mobile,
      url,
      fullWidth,
      header,
      manchet,
      imageAltText,
    } = this.props;

    const { desktop } = this.state;

    let mobileImage = null;
    let displayMobile = null;
    let noText = null;

    if (mobile != null) {
      mobileImage = mobile;
      displayMobile = true;
    } else {
      mobileImage = image;
      displayMobile = false;
    }

    if (header != null && manchet != null) {
      noText = true;
    } else {
      noText = false;
    }

    return (
      <div role="banner" aria-label="Frontpage">
        {desktop ? (
          <Url url={url}>
            <BannerOverlayText image={image} fullSize={fullWidth} altText={imageAltText} mobile={false}>
              {this.renderContent()}
            </BannerOverlayText>
          </Url>
        ) : (
          <Url url={url}>
            <BannerOverlayText
              image={mobileImage}
              fullSize={fullWidth}
              displayMobile={displayMobile}
              noText={noText}
              altText={imageAltText}
              mobile
            >
              {this.renderContent()}
            </BannerOverlayText>
          </Url>
        )}
      </div>
    );
  }
}

// Proptypes
Banner.propTypes = {
  header: PropTypes.string.isRequired,
  manchet: PropTypes.string.isRequired,
  urlText: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  image: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  url: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
};

Banner.defaultProps = {
  fullWidth: undefined,
  mobile: undefined,
  imageAltText: 'Image No Description',
};

// Exports
export default Banner;
