// Import Node Modules
import { css } from 'styled-components';

// CSS Header

const common = css`
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const globalHeader = css`
  .application h1 {
    ${common};
    line-height: 1.15;
    margin-bottom: 24px;
    font-weight: 700;
    text-align: center;

    font-size: 20px;
    @media screen and (min-width: 768px) {
      font-size: 34px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 42px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 50px;
    }
  }

  .application h2 {
    ${common};
    margin-bottom: 17px;
    font-weight: 700;

    font-size: 20px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 30px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 35px;
    }
  }

  .application h3 {
    ${common};
    line-height: 1.5;
    font-weight: 700;

    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 20px;
    }
  }
`;

export default globalHeader;
