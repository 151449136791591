import React from 'react';
import PropTypes from 'prop-types';


export const Link = ({ to, children, ...props }) => (
  <a {...props} href={to}>{children}</a>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const navigate = (to) => {
  global.location.href = to;
};
