// Component Name : TextArea
// C1 CMS Name : TextBox

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Color Variables
import { margins } from '../../../cssGuides/commonStyles';

// Styled Components

const Container = styled.div`
  background-color: ${props => (props.color === 'Primary' ? props.theme.PrimaryColor : props.color)};
  border-radius: 7px;
  ${margins};
`;

const Inner = styled.div`
  padding: 30px 30px 0.1px 30px;
`;

// Class
const TextArea = (props) => {
  const { color, children } = props;
  return (
    <Container color={color} role="none">
      <Inner role="none">{children}</Inner>
    </Container>
  );
};

// Proptypes
TextArea.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Exports
export default TextArea;
