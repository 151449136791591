// Import Node Modules
import styled from 'styled-components';

// Media Query
import { media } from '../../../cssGuides/MediaQuerys';

// Color Variables
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Import components
import { ReadMore } from '../../CommonComponents/index';

// Styled-Components
export const Header = styled.h1`
  margin-bottom: 10px;
  color: ${ColorGuide.white};
  text-align: center;
  text-shadow: 1px 1px 2px ${ColorGuide.darkGray};
  ${media.tabletP`
  margin-top:0px;
    margin-bottom: 16px;
  `}
  ${media.tabletL`
    margin-bottom: 20px;
  `}
  ${media.desktop`
    ${props => (props.fullSize === null ? '' : 'font-size: 60px !important')}
    margin-bottom: 24px;
  `}  
`;

// Manchet is disabled on Mobile
export const Manchet = styled.p`
  text-align: center;
  color: ${ColorGuide.white};
  margin-bottom: 10px !important;
  text-shadow: 1px 1px 2px ${ColorGuide.darkGray};
`;

export const ReadMoreStyled = styled(ReadMore)`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorGuide.white};
  width: 100%;
  text-align: center;
  margin-top: -5px;
  text-shadow: 1px 1px 2px ${ColorGuide.darkGray};
  ${media.tabletP`
    margin-top: -25px;
  `}
  ${media.tabletL`
    margin-top: -20px;
  `}
  ${media.desktop`
    ${props => (props.fullSize != null ? 'margin-top: -10px' : 'margin-top: 10px')}
  `}
`;
