import PropTypes, { element } from 'prop-types';

const DefaultPropTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    theme: PropTypes.string,
    bottomContent: PropTypes.arrayOf(element),
    level_1_id: PropTypes.string,
    cookieBanner: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    desktopTools: PropTypes.shape({
      bestil: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      chat: PropTypes.shape({
        text: PropTypes.string,
        offlineText: PropTypes.string,
      }),
      ring: PropTypes.shape({
        text: PropTypes.string,
        info: PropTypes.string,
      }),
    }),
    disableScroll: PropTypes.bool,
    footer: PropTypes.shape({
      afdelinger: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })),
      firm: PropTypes.shape({
        address: PropTypes.string,
        city: PropTypes.string,
        cvr: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
      }),
      links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })),
      social: PropTypes.shape({
        Facebook: PropTypes.string,
        LinkedIn: PropTypes.string,
        Twitter: PropTypes.string,
      }),
      terms: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })),
    }),
    megaMenu: PropTypes.shape({
      megaMenu: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        menu: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
        })),
      })),
    }),
    nav: PropTypes.shape({
      loginUrl: PropTypes.string,
      searchUrl: PropTypes.string,
      menus: PropTypes.shape({
        subMenus: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          internal: PropTypes.bool,
          display: PropTypes.string,
          menuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })),
        })),
      }),
    }),
    pageDescription: PropTypes.string,
    pageTitle: PropTypes.string,
  }).isRequired,
};

export default DefaultPropTypes;
