// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Ordered List

export const globalOrderedList = css`
  ol {
    padding-left: 32px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: 0.2px;
    color: ${ColorGuide.darkGray};
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }

    li {
      padding-left: 1em;
      text-indent: -0.7em;
    }
  }
`;

export const globalUnOrderedList = css`
  ul {
    list-style: none;
    padding: 0 0 0 20px;
    margin: 0;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: 0.2px;
    color: ${ColorGuide.darkGray};
    margin-bottom: 34px;

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }

    li {
      padding-left: 1em;
      text-indent: -0.7em;
    }

    li::before {
      content: '• ';
      color: ${props => props.theme.PrimaryColor};
    }
  }
`;
