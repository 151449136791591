// Common Component : Goto

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Import Components
import { IconRight } from '../UniCodeIcons/index';

// Styled-components
const Container = styled.div`
  line-height: 32px;
  white-space: nowrap;
  font-size: 16px;
`;

const Text = styled.span`
  padding-left: 10px;
`;

// Class
const Goto = (props) => {
  const { url, text } = props;

  return (
    <Container role="link" aria-label={text}>
      <Link to={url} aria-hidden="true">
        <IconRight />
        <Text>{text}</Text>
      </Link>
    </Container>
  );
};

// Proptypes
Goto.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

// Exports
export default Goto;
