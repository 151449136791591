class PuzzelClient {
  constructor(id) {
    this.id = id;
  }

  loader = undefined;

  markReady = undefined;

  ready = new Promise((resolve) => {
    this.markReady = resolve;
  });

  attach = subscriptions => () => {
    this.loader = new global.EUWALoader({
      customerKey: 43863,
      configId: this.id,
    }, {
      onBeforeLoad: () => {
        const chat = this.loader.getApplication('PuzzelChatClient');
        Object.entries(subscriptions).forEach(([name, listener]) => {
          chat.subscribe(name, listener);
        });
      },
      settings: {
        PuzzelChatClient: {
          variables: {},
        },
      },
    });
    return this.loader.load().then(async () => {
      const chatQueueUrl = 'https://api.puzzel.com/chat/v1/queue/43863/status/71759';
      const chatResponse = await fetch(chatQueueUrl);
      const chatQueueDataJson = await chatResponse.json();
      const { agentsReady } = chatQueueDataJson;

      const chatAgentsReadyThresholdUrl = 'https://api.puzzel.com/chat/v1/time/43863/37235_time10';
      const agentsResponse = await fetch(chatAgentsReadyThresholdUrl);
      const agentsReadyThreshold = await agentsResponse.json();

      this.markReady(agentsReady >= agentsReadyThreshold);
    }).catch((err) => {
      // NOTE: If chat is closed the module throws an error, and since we can not detect this ahead of time we need
      // this horrible hack
      if (err.toString() === 'NotAvailableError: [Bootstrapper] Time module returned not opened exit - Closed') {
        this.markReady(false);
        return;
      }
      throw err;
    });
  };

  init = (subscriptions = {}) => {
    if (this.loader) {
      return undefined;
    }
    if (!global.window && global.document.getElementById('puzzlescript')) {
      return undefined;
    }
    const loader = global.document.createElement('script');
    loader.id = 'puzzlescript';
    loader.src = 'https://euwa.puzzel.com/loader/index.js';
    loader.onload = this.attach(subscriptions);
    global.document.body.append(loader);
    return this.ready;
  };

  startChat = async () => {
    await this.ready;
    const chat = this.loader.getApplication('PuzzelChatClient');
    const { isConnected } = chat.api.getState();
    if (!isConnected) {
      chat.api.startChat();
    }
  };

  minimize = async () => {
    await this.ready;
    const chat = this.loader.getApplication('PuzzelChatClient');
    const { isMinimized } = chat.api.getState();
    if (!isMinimized) {
      chat.api.minimize();
    }
  };

  maximize = async () => {
    await this.ready;
    const chat = this.loader.getApplication('PuzzelChatClient');
    const { isMaximized } = chat.api.getState();
    if (!isMaximized) {
      chat.api.maximize();
    }
  };

  setSystemData = async (values) => {
    await this.ready;
    const chat = this.loader.getApplication('PuzzelChatClient');
    chat.api.updateSystemVariables(values);
  }

  setData = async (values) => {
    await this.ready;
    const chat = this.loader.getApplication('PuzzelChatClient');
    chat.api.updateVariables(values);
  }
}

export default PuzzelClient;
