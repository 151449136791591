// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

// Icons
import LinkedIn from '../../../assets/Icons/svg/icon_Social_LinkedIn.svg';
import Facebook from '../../../assets/Icons/svg/icon_Social_Facebook.svg';

// Import Components
import { Columns, AlignContentCenter, Url } from '../../CommonComponents/index';

import {
  Container,
  ColDiv,
  Address,
  Info,
  Phone,
  Social,
  Urls,
} from './styledcomponents';

// Class
const Footer = withTheme((props) => {
  const { footer, theme } = props;
  return (
    <Container aria-label="Footer">
      <Columns columns={6} minWidth="1023px">
        <ColDiv>
          <AlignContentCenter>
            <Address tabIndex="0" role="contentinfo" aria-label="address">
              {footer.firm.name}
              <br role="none" />
              <Info>
                {footer.firm.address}
                <br role="none" />
                {footer.firm.city}
                <br role="none" />
                cvr: {footer.firm.cvr}
                <br role="none" />
                <Phone>tlf: {footer.firm.phone}</Phone>
              </Info>
            </Address>
          </AlignContentCenter>
        </ColDiv>

        <ColDiv />

        <ColDiv>
          {theme.FooterLinks !== false && (
            <AlignContentCenter>
              <Urls>
                {footer.links.map((link, index) => (link.name !== '' ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} role="none">
                    <Url url={link.url} name={link.name} />
                  </div>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} aria-hidden>
                    &nbsp;
                  </div>
                )))}
              </Urls>
            </AlignContentCenter>
          )}
        </ColDiv>

        <ColDiv>
          {theme.FooterLinks !== false && (
            <AlignContentCenter>
              <Urls>
                {footer.terms.map((link, index) => (link.name !== '' ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} role="none">
                    <Url url={link.url} name={link.name} />
                  </div>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} aria-hidden>
                    &nbsp;
                  </div>
                )))}
              </Urls>
            </AlignContentCenter>
          )}
        </ColDiv>

        <ColDiv>
          {theme.FooterLinks !== false && (
            <AlignContentCenter>
              <Urls>
                {footer.afdelinger.map((link, index) => (link.name !== '' ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} role="none">
                    <Url url={link.url} name={link.name} />
                  </div>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} aria-hidden>
                    &nbsp;
                  </div>
                )))}
              </Urls>
            </AlignContentCenter>
          )}
        </ColDiv>

        <ColDiv>
          <Columns columns={2} minWidth="300px">
            <Social>
              <AlignContentCenter>
                {footer.social.linkedIn !== '' && (
                  <a
                    href={footer.social.linkedIn}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="LinkedIn"
                  >
                    <img src={LinkedIn} alt="LinkedIn" />
                  </a>
                )}
              </AlignContentCenter>
            </Social>
            <Social>
              <AlignContentCenter>
                {footer.social.facebook !== '' && (
                <a
                  href={footer.social.facebook}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Facebook"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
                )
              }
              </AlignContentCenter>
            </Social>
          </Columns>
        </ColDiv>
      </Columns>
    </Container>
  );
});

// Proptypes
Footer.propTypes = {
  footer: PropTypes.shape({
    firm: PropTypes.shape({
      afdelinger: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      firm: PropTypes.shape({
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        cvr: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
      }),
      links: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      social: PropTypes.shape({
        Facebook: PropTypes.string.isRequired,
        LinkedIn: PropTypes.string.isRequired,
        Twitter: PropTypes.string.isRequired,
      }),
      terms: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default Footer;
