// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Button

const globalModal = css`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index:4;
  }

  .modal-main {
    position:fixed;
    background: ${ColorGuide.white};
    width:90%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius:10px;
    padding:30px;

    @media only screen and (min-width: 600px) {
      width: 600px;
      height: 460px;
    }
  }
`;

export default globalModal;
