import { createGlobalStyle } from 'styled-components';

import globalButton from './globalButton';
import globalForm from './globalForm';
import globalCheckbox from './globalCheckbox';
import globalRadioButton from './globalRadioButton';
import { globalOrderedList, globalUnOrderedList } from './globalList';
import embedResponsive16by9 from './embed-responsive-16by9';
import globalHelperClasses from './globalHelperClasses';
import globalBody from './globalBody';
import globalModal from './globalModal';
import globalHeader from './globalHeader';
import globalParagraph from './globalParagraph';
import globalLink from './globalLink';
import globalFocus from './globalFocus';

const GlobalStyle = createGlobalStyle`
  ${globalButton};
  ${globalForm};
  ${globalCheckbox};
  ${globalRadioButton};
  ${globalOrderedList};
  ${globalUnOrderedList};
  ${embedResponsive16by9};
  ${globalHelperClasses};
  ${globalBody};
  ${globalModal};
  ${globalHeader};
  ${globalParagraph};
  ${globalLink};
  ${globalFocus};
`;

export default GlobalStyle;
