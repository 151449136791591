// Common Component : ReadMore

// Component used in : Banner.

// Import Node Modules
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Color Variables
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Import Images
import arrowRightWhite from '../../../assets/Icons/svg/icon_Arrow_Right_White.svg';
import arrowRightBlue from '../../../assets/Icons/svg/icon_Arrow_Right_Blue.svg';

// Styled-Components
const IconShadow = css`
  -webkit-filter: drop-shadow( 1px 1px 2px ${ColorGuide.darkGray});
  filter: drop-shadow( 1px 1px 2px ${ColorGuide.darkGray});
`;

const Icon = styled.img.attrs({
  alt: 'Arrow Right',
})`
  width: 20px;
  height: 14px;
  position: relative;
  top: 3px;
  margin-left: 17px;
  ${props => (props.shadow === true ? null : IconShadow)}

`;

// Class
const ReadMore = (props) => {
  const {
    url,
    text,
    className,
    blue,
  } = props;

  if (url != null) {
    return (
      <Link to={url} className={className}>
        {text}
        <Icon shadow={blue} src={blue ? arrowRightBlue : arrowRightWhite} />
      </Link>
    );
  }
  return (
    <div className={className}>
      <p style={{ marginBottom: '0px' }}>
        {text}
        <Icon shadow={blue} src={blue ? arrowRightBlue : arrowRightWhite} />
      </p>
    </div>
  );
};

// Proptypes
ReadMore.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  blue: PropTypes.bool,
};

ReadMore.defaultProps = {
  url: undefined,
  className: undefined,
  blue: undefined,
};

// Exports
export default ReadMore;
