// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Icons
import Arrow from '../../../../assets/Icons/svg/icon_Arrow_Right_Blue.svg';
import DefaultImage from '../../../../assets/DefaultImages/NewsCard.png';

// Import Guides
import { media } from '../../../../cssGuides/MediaQuerys';

// Styled Component
const Container = styled.div`
  max-height: 160px;
  position: relative;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: none;
  ${media.tabletP`
    display: inline-block;
    float: left;
    margin-right: 24px;
    width: 184px;
  `};
`;

const TextContainer = styled.div`
  padding: 20px 24px;
`;

const TimeStamp = styled.p`
  margin: 0 0 4px;
`;

const Header = styled.h2`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 24px;
`;

const Manchet = styled.p`
  position: relative;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 48px;
  margin: 0 auto;
  
  height: 32px;
  ${media.tabletP`
    height: 40px;
  `}
  ${media.tabletL`
    height: 40px;
  `}
  ${media.desktop`
    height: 48px;
  `}
`;

const BlueArrow = styled.img`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

// Component
const News = (props) => {
  const {
    image,
    imageRight,
    altText,
    header,
    manchet,
    timestamp,
    arrow,
  } = props;

  const ImageSide = image !== '' ? (
    <ImageContainer>
      <img
        src={image}
        alt={altText}
        style={{ width: '100%' }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DefaultImage;
          e.target.alt = 'Default Image';
        }}
      />
    </ImageContainer>
  ) : null;

  const TextSide = (
    <TextContainer>
      <TimeStamp className="footnote">{timestamp}</TimeStamp>
      <Header>{header}</Header>
      <Manchet>{manchet}</Manchet>
      {arrow === true ? (
        <BlueArrow
          style={imageRight === true ? { right: '190px' } : null}
          src={Arrow}
          alt="Arrow right"
        />
      ) : null}
    </TextContainer>
  );

  return (
    <Container>
      {imageRight === true ? TextSide : ImageSide}
      {imageRight === true ? ImageSide : TextSide}
    </Container>
  );
};

News.propTypes = {
  /** Header Text */
  header: PropTypes.string.isRequired,
  /** Manchet Text */
  manchet: PropTypes.string.isRequired,
  /** Manchet Text */
  timestamp: PropTypes.string.isRequired,
  /** Image url */
  image: PropTypes.string,
  /** Image Alt Text */
  altText: PropTypes.string,
  /** What side is image displayed */
  imageRight: PropTypes.bool,
  /** Arrow pointing right */
  arrow: PropTypes.bool,
};

News.defaultProps = {
  image: '',
  imageRight: true,
  altText: 'Alt text is missing',
  arrow: true,
};

export default News;
