// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Button

const globalCheckbox = css`
  input[type='checkbox'] {
    position: absolute;
    outline: none;
    opacity: 0;

    &:focus {
      + label {
        &:before {
          box-shadow: 0 0 5px #0000ff;
        }
      }
    }

    &:checked {
      + label {
        &:after {
          opacity: 1;
        }
      }
    }

    + label {
      position: relative;
      cursor: pointer;
      padding-left: 28px;
      line-height: 30px;
      padding-top: 2px;
      &:before {
        position: absolute;
        left: 0;
        top: -1px;
        content: '';
        background: ${ColorGuide.white};
        width: 25px;
        height: 25px;
        display: block;
        border-radius: 5px;
        border: 3px solid ${ColorGuide.white};
      }
      &:after {
        border-radius: 5px;
        content: '✔';
        position: absolute;
        background: ${props => props.theme.PrimaryColor};
        top: -1px;
        left: 0;
        width: 25px;
        height: 25px;
        line-height: 20px;
        text-align: center;
        color: ${ColorGuide.white};
        border: 3px solid ${ColorGuide.white};
        opacity: 0;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
    }
  }
`;

export default globalCheckbox;
