// Component Name : BlockArea
// C1 CMS Name : FrontpageSection

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

const MediaQuery = css`
  padding: 20px 20px 10px 20px;
  ${media.tabletP`
    padding: 40px 40px 20px 40px;
  `}
  ${media.tabletL`
    padding: 60px 60px 20px 60px;
  `}
  ${media.desktop`
    padding: 80px 80px 20px 80px;
  `}
`;

// Styled Component
const Area = styled.div`
  width: 100%;
  background-color: ${props => (props.backgroundColor === 'Primary' ? props.theme.PrimaryColor : props.backgroundColor)};
  box-sizing: border-box;
  ${MediaQuery}
`;

// Component
const BlockArea = (props) => {
  const { children, backgroundColor } = props;
  return <Area backgroundColor={backgroundColor}>{children}</Area>;
};

// Proptypes
BlockArea.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Exports
export default BlockArea;
