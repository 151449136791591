// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Color Variables
import { media } from '../../../../cssGuides/MediaQuerys';

// Styled Components
const Container = styled.div`
  ${props => (props.url !== '' ? 'cursor: pointer' : 'cursor: auto')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  box-sizing: border-box;
  margin-bottom: 24px;
  
  ${media.tabletP`
    margin-bottom: 40px;
  `};
  ${media.tabletL`
    margin-bottom: 40px;
  `};
  ${media.desktop`
    margin-bottom: 60px;
  `};
`;

const MyLink = styled.a`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color:#464543 !important;
`;

const GatsbyLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color:#464543 !important;
`;

// Functions
function OnClickUrl(url, children) {
  if (url.indexOf('http') === 0) { // eslint-disable-line react/destructuring-assignment
    return (
      <MyLink href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </MyLink>
    );
  }
  return <GatsbyLink to={url}>{children}</GatsbyLink>;
}

// Components
const ContainerLink = (props) => { // eslint-disable-line react/no-multi-comp
  const {
    url,
    children,
    className,
    maxWidth,
  } = props;

  return (
    <Container
      role="button"
      className={className}
      maxWidth={maxWidth}
      url={url}
    >
      { url !== '' ? OnClickUrl(url, children) : children}
    </Container>
  );
};

// Proptypes
ContainerLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

ContainerLink.defaultProps = {
  children: undefined,
  className: undefined,
  url: '',
  maxWidth: '100%',
};

// Exports
export default ContainerLink;
