// Import Node Modules
import styled from 'styled-components';

// Import Color Guide
import * as ColorGuide from '../../cssGuides/colorGuide';

// Media Query Variables
import { media } from '../../cssGuides/MediaQuerys';

// Variables
const sizeContent = '1060px';

// Common Styles

const Content = styled.div`
  overflow: visible;
  position: relative;
  margin: 0 auto;
  border-radius: 7px;
  width: 95%;
  ${props => (props.displayHeader ? 'margin-top:20px' : 'margin-top:0px')};

  ${media.tabletP`
    border-radius: 10px;
    width: 100%;
  `};

  ${media.desktop`
    border-radius: 15px;
    width: ${sizeContent};
  `};
`;

// Styled Components
export const Template2Content = styled(Content)`
  ${media.desktop`
    margin-top: ${props => props.displayHeader}px
  `};
`;

export const Template3Content = styled(Content)`
  background: ${ColorGuide.white};
  width: 95%;

  padding: 20px 20px 10px 20px;

  ${media.tabletP`
    padding: 50px 50px 10px 50px;
  `};
  ${media.tabletL`
    padding: 60px 60px 20px 60px;
  `};
  ${media.desktop`
    width: ${sizeContent};
    padding: 80px 80px 20px 80px;
    margin-top: ${props => props.displayHeader}px
  `};
`;
