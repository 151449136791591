/* eslint-disable eqeqeq */
// Import Node Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import Components
import { ColumnsDefined } from '../../CommonComponents';

import {
  Container,
  SelectInfo,
  InfoTextBox,
  SelectInfoSub,
  SelectBox,
  SelectInfoBox,
  Icon,
} from './styledcomponents';

// Class

class InfoTagsPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeInfo: 1,
      subInfo: null,
      subMenuInfo: null,
    };
  }

  getCurrentText() {
    let t = null;
    const { subMenuInfo, activeInfo } = this.state;
    const { children } = this.props;
    if (subMenuInfo == null) {
      t = children[activeInfo - 1];
    } else {
      t = children[activeInfo - 1];
    }
    return t;
  }

  changeInfo(x) {
    const { activeInfo, subInfo } = this.state;
    if (x === activeInfo && subInfo != null) {
      this.setState({
        activeInfo: x,
        subInfo: null,
        subMenuInfo: null,
      });
    } else {
      this.setState({
        activeInfo: x,
        subInfo: x,
        subMenuInfo: null,
      });
    }
  }

  changeInfoSub(x, y) {
    this.setState({
      activeInfo: y,
      subMenuInfo: y,
    });
  }

  render() {
    const { activeInfo, subInfo } = this.state;
    const { menu } = this.props;

    return (
      <div>
        <Container>
          <ColumnsDefined columns="33% 66%" style={{ flexWrap: 'nowrap' }}>
            <SelectBox>
              {menu.map(menuItem => (
                <div key={menuItem.id}>
                  <SelectInfo onClick={() => this.changeInfo(`${menuItem.id}`)}>
                    <span>
                      {menuItem.header}
                      {activeInfo == menuItem.id && <Icon>&#x25B6;</Icon>}
                    </span>
                  </SelectInfo>

                  <SelectInfoBox
                    /* eslint-disable eqeqeq */
                    visible={subInfo == menuItem.id}
                    height={this[menuItem.id] && this[menuItem.id].clientHeight}
                  >
                    <div
                      ref={(r) => {
                        this[menuItem.id] = r;
                      }}
                    >
                      {menuItem.subMenu.map(subMenu => (
                        <SelectInfoSub key={subMenu.id}>
                          <ColumnsDefined columns="auto 15px">
                            <div
                              onClick={() => this.changeInfoSub(
                                `${menuItem.id}`,
                                `${subMenu.id}`,
                              )
                              }
                              role="button"
                              tabIndex="0"
                            >
                              {subMenu.header}
                            </div>
                            <div>
                              {activeInfo == subMenu.id && (
                                <Icon>&#x25B7;</Icon>
                              )}
                            </div>
                          </ColumnsDefined>
                        </SelectInfoSub>
                      ))}
                    </div>
                  </SelectInfoBox>
                </div>
              ))}
            </SelectBox>
            <div>
              <InfoTextBox>{this.getCurrentText()}</InfoTextBox>
            </div>
          </ColumnsDefined>
        </Container>
      </div>
    );
  }
}

InfoTagsPages.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      header: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          header: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

// Exports
export default InfoTagsPages;
