// Import Node Modules
import { css } from 'styled-components';

// CSS 16by9 video
const embedResponsive16by9 = css`
  .embed-responsive-16by9 {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border: 0;
    }
  }
`;

export default embedResponsive16by9;
