// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Common Components
import { Wrapper, Columns, IconRight } from '../../../CommonComponents';

// Import Styled-components
import {
  Overlay,
  MegaMenuContainer,
  MegaMenuItem,
  MegaMenuItemText,
} from '../StyledComponents/MegaMenu';

import * as ColorGuide from '../../../../cssGuides/colorGuide';

// Component Class MegaMenu
const MegaMenu = (props) => {
  const {
    MegaMenuVisible,
    ActiveMegaMenu,
    size,
    megaMenu,
    changeMegaMenu,
  } = props;

  const arrangeMenuItems = (y) => {
    const MenuCount = Math.ceil(y.length / 3) * 3;
    const Col1Count = Math.ceil(MenuCount / 3);
    const newCount = MenuCount - Col1Count;
    const Col2Count = Math.ceil(newCount / 2);
    const col1 = [];
    const col2 = [];
    const col3 = [];
    let x = {};

    for (let i = 0; i < Col1Count; i += 1) {
      x = {
        id: y[i].id,
        url: y[i].url,
        name: y[i].name,
      };
      col1.push(x);
    }

    for (let i = Col1Count; i < Col1Count + Col2Count; i += 1) {
      if (y[i] !== undefined) {
        x = {
          id: y[i].id,
          url: y[i].url,
          name: y[i].name,
        };
        col2.push(x);
      } else {
        x = {
          id: null,
          url: '',
          name: null,
        };
        col2.push(x);
      }
    }

    for (let i = Col1Count + Col2Count; i < MenuCount; i += 1) {
      if (y[i] !== undefined) {
        x = {
          id: y[i].id,
          url: y[i].url,
          name: y[i].name,
        };
        col3.push(x);
      } else {
        x = {
          id: null,
          url: '',
          name: null,
        };
        col3.push(x);
      }
    }

    const newArray = [];

    for (let i = 0; i < Col1Count; i += 1) {
      newArray.push(col1[i]);
      if (col2[i] !== undefined) {
        newArray.push(col2[i]);
      }
      if (col3[i] !== undefined) {
        newArray.push(col3[i]);
      }
    }

    return newArray;
  };

  return (
    <Overlay
      style={{ display: MegaMenuVisible ? 'block' : 'none' }}
      onClick={() => {
        changeMegaMenu(ActiveMegaMenu);
      }}
    >
      <MegaMenuContainer role="menu" aria-label="Mega Menu Content">
        <Wrapper minWidth={size}>
          {megaMenu.megaMenu.map((megaMenuItem) => {
            return ActiveMegaMenu === megaMenuItem.name ? (
              <Columns key={megaMenuItem.id} columns={3}>
                {arrangeMenuItems(megaMenuItem.menu).map(menu => (
                  <div key={menu.id} role="none">
                    <MegaMenuItem
                      role="menuitem"
                      to={menu.url}
                      onClick={() => {
                        changeMegaMenu(`${menu.id}`);
                      }}
                    >
                      {menu.id !== null && <IconRight color={ColorGuide.white} />}
                      <MegaMenuItemText>{menu.name}</MegaMenuItemText>
                    </MegaMenuItem>
                  </div>
                ))}
              </Columns>
            ) : (
              ''
            );
          })}
        </Wrapper>
      </MegaMenuContainer>
    </Overlay>
  );
};

// Proptypes
MegaMenu.propTypes = {
  MegaMenuVisible: PropTypes.bool.isRequired,
  ActiveMegaMenu: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  megaMenu: PropTypes.shape({
    megaMenu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        menu: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })),
      }).isRequired,
    ).isRequired,
  }).isRequired,
  changeMegaMenu: PropTypes.func.isRequired,
};

export default MegaMenu;
