// Component Name : BlockAreaFullWidth
// C1 CMS Name : FrontpageSectionFullWIdth

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

const MediaQuery = css`
  padding: 20px 20px 20px 20px;
  margin: 0 -10px 0 -10px;
  width: calc(100% + 20px);
  ${media.tabletP`
    padding: 40px 40px 20px 40px;
    margin: 0 -50px 0px -50px;
    width: calc(100% + 100px);
  `}
  ${media.tabletL`
    padding: 60px 60px 20px 60px;
    margin: 0 -60px 0 -60px;
    width: calc(100% + 120px);
  `}
  ${media.desktop`
    padding: 80px 80px 20px 80px;
    margin: 0 -80px 60px -80px;
    width: calc(100% + 160px);
  `}
`;

// Styled Components
const AreaFullWidth = styled.div`
  background-color: ${props => (props.backgroundColor === 'Primary' ? props.theme.PrimaryColor : props.backgroundColor)};
  box-sizing: border-box;
  ${MediaQuery}
`;

// Component
const BlockAreaFullWidth = (props) => {
  const { children, backgroundColor } = props;
  return <AreaFullWidth backgroundColor={backgroundColor}>{children}</AreaFullWidth>;
};

// Proptypes
BlockAreaFullWidth.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Exports
export default BlockAreaFullWidth;
