// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Import Components
export const Container = styled.article`
  background-color: white;
  position:relative;
  margin-bottom: 40px;
  border-radius: 10px;
  width: 100%;
  margin: 10px auto;
 
  ${media.tabletP`
    box-shadow: ${ColorGuide.shadow};
    width: 95%;
    padding:0;
  `}
  ${media.tabletL`

  `}
  ${media.desktop`
    height: 100%;
    width: 100%;
  `};

`;

export const Content = styled.div`
  z-index:1;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: -1px;
  position: relative;
  border-top-right-radius:10px;
  border-top-left-radius:10px;
  background-color:${ColorGuide.white};
  
  h2 {
    padding:10px 10px 0px 10px;
  }

  ${media.tabletP`
    h2 {
      padding:10px 40px 20px 0px;
    }
    p {
      line-height: 1.4;
    }
    text-align:center;
  `}
  ${media.tabletL`
    h2 {
      padding:25px 40px 20px 0px;
    }
    p {
      line-height: 1.3;
    }
    text-align:left;
  `}
  ${media.desktop`
    p {
      font-size: 20px;
      margin:15px 0 -10px 0;
    }
    h2 {
      font-size:40px;
      padding:10px 40px 20px 0px;
    }
  `}
`;

export const Inner = styled.div`
  padding: 10px;
  ${media.tabletL`
    padding: 40px 0px 30px 100px;
  `};
  ${media.desktop`
    padding: 40px 0px 30px 80px;
  `};
`;

export const Image = styled.img`
  width:720px;

  ${media.tabletP`
    padding-top:20px;
    width:100%;
    margin: 0 auto;
  `}
  ${media.tabletL`
    position:absolute;
    z-index:2;
    width:600px;
    left:-20px;
    top:50px;
  `}
  ${media.desktop`
    width:720px;
    left:-110px;
    top:40px;
  `}
`;

export const Image2 = styled.img`
  position:relative;
  top:4px;
  width:250px;
  margin-bottom:15px;

  ${media.tabletP`
    z-index:2;
    position:absolute;
    width:120px;
    right:50px;
    top:175px;
  `}
  ${media.tabletL`
    width:80px;
    left:440px;
    top:250px;
  `}
  ${media.desktop`
    width:100px;
    left:440px;
    top:260px;
  `}
`;

// Blue Text Container

export const TextContainer = styled.div`
  background-color: rgba(84, 168, 209, 0.2);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const TextBlock = styled.div`
  padding: 30px 20px 1px 20px;
  ${media.tabletP`
    padding: 32px 40px 1px 40px;
  `}
  ${media.tabletL`
    padding: 32px 40px 1px 40px;
  `}
  ${media.desktop`
    padding: 32px 40px 1px 40px;
  `}
  p {
    line-height: 1.5 !important;
    letter-spacing: normal !important;
  }
`;

export const Bullets = styled.div`
  li {
    font-size: 16px;
  }

  width: 100%;

  ${media.tabletL`
  width: 840px;
    margin: -30px auto 0 auto;
  `};
`;

export const LastText = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 11px;
  ${media.tabletP`
    font-size: 11px;
    width:90%;
  `}
  ${media.tabletL`
    font-size: 10px;
    width:100%;
  `}
  ${media.desktop`
    font-size: 11px;
  `}

  a {
    font-size: 11px;
    ${media.tabletP`
    font-size: 11px;

    `}
    ${media.tabletL`
      font-size: 10px;
    `}
    ${media.desktop`
      font-size: 11px;
    `}
  }
`;
