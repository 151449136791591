// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import BannerOverlayText from '../components/PageComponents/Banner/overlay';
import { AlignContentCenter } from '../components/CommonComponents/index';

// Import Styled-Components
import BannerContent from './components/BannerContent';

// Component
const Overlay = (props) => {
  const {
    image,
    content,
    bottomMargin,
    displayMobile,
    imageAltText,
    mobile,
  } = props;

  return (
    <BannerOverlayText
      image={image}
      role="banner"
      displayMobile={displayMobile}
      noText={content !== ''}
      altText={imageAltText}
      mobile={mobile}
    >
      <AlignContentCenter>
        <BannerContent
          dangerouslySetInnerHTML={{ __html: content }}
          bottomMargin={bottomMargin}
        />
      </AlignContentCenter>
    </BannerOverlayText>
  );
};

// Proptypes
Overlay.propTypes = {
  image: PropTypes.string.isRequired,
  content: PropTypes.string,
  bottomMargin: PropTypes.string,
  displayMobile: PropTypes.bool,
  imageAltText: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
};

Overlay.defaultProps = {
  displayMobile: undefined,
  content: null,
  bottomMargin: undefined,
  imageAltText: 'Image No Description',
};

export default Overlay;
