import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as ColorGuide from '../../../cssGuides/colorGuide';

export const CloseBtn = styled.div`
  font-size: 25px;
  font-weight: normal;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: ${ColorGuide.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${ColorGuide.black};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
`;

export const OkBtn = styled.div`
  font-size: 25px;
  font-weight: normal;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  left: 280px;
  background-color: ${ColorGuide.green};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Modal = ({
  handleClose,
  show,
  children,
  posted,
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName} onClick={handleClose} role="button" tabIndex="0">
      <div
        role="presentation"
        className="modal-main"
        style={{
          position: 'relative',
          textAlign: 'center',
          backgroundColor: 'white',
        }}
        onClick={e => e.stopPropagation()}
      >
        {children}
        {posted ? (
          <OkBtn onClick={handleClose}>&#x2714;</OkBtn>
        ) : (
          <CloseBtn onClick={handleClose}>&#x254B;</CloseBtn>
        )}
      </div>
    </div>
  );
};

// Proptypes
Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  posted: PropTypes.bool.isRequired,
};

export default Modal;
