// Import Node Modules
import styled from 'styled-components';

// Import Guides
import * as Variables from '../Variables';
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { media } from '../../../../cssGuides/MediaQuerys';

// Styled Components - MainMenuBar
export const MenuContainer = styled.nav`
  background-color: ${ColorGuide.white};
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export const ContentContainer = styled.div``;

export const MenuList = styled.div`
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  height: ${Variables.level1}px;
`;

export const MenuItem = styled.div`
  position: relative;
  display: block;
  color: ${ColorGuide.lightGray};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 7px;
  margin: 10px 0;

  a,
  a:visited {
    color: ${ColorGuide.darkGray} !important;
    font-size: 13px;

  ${media.tabletP`font-size: 11px;`};
  ${media.tabletL`font-size: 13px;`};
  ${media.desktop`font-size: 13px;`};

  }

  &:nth-last-child(-n + 2) {
    border-left: ${props => (props.theme.MainMenuSeperator ? `1px solid ${ColorGuide.border}` : 'none')};
  }
`;

export const MenuItemUnderline = styled.div`
  position: absolute;
  bottom: 0px;

  width: calc(100% - 32px);
  height: 2px;
  background-color: ${({ item, isActive }) => (item === isActive ? ColorGuide.darkGray : ColorGuide.white)};
`;

export const LogOut = styled.div`
  display: block;
  border: none;
  cursor: pointer;
  font-size: 13px;
  color: ${ColorGuide.darkGray}
`;
