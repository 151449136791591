// Import Node Modules
/* eslint-disable */
import React from 'react'
import Components from '../components';
import { isProduction } from "./helperFunctions";

import { PuzzelProvider } from './components/DesktopTools/PuzzelContext';

const prodPuzzelIds = {
  sampension: 'b065bb6c-9c03-4f6a-8b78-4ee21413c908',
  firma: 'b065bb6c-9c03-4f6a-8b78-4ee21413c908',
  isp: '94b01c06-59a4-491d-953e-e5fb9e01e252',
  ap: '35bd74d3-112e-4443-8b5c-aa207175ea00',
  pjd: 'be3e12fb-7e93-4074-950c-fcfad4327a80',
};

// for now all company groups share the same Id
const testPuzzleId = '3f9180cd-57da-4cb7-80c9-5664dd8c6249';

const testPuzzleIds = {
  sampension: testPuzzleId,
  firma: testPuzzleId,
  isp: testPuzzleId,
  ap: testPuzzleId,
  pjd: testPuzzleId,
};

const findRenderComponent = (data, id) => {
  if (data !== null) {
    if (typeof data === 'object' && data.componentName) {
      return renderComponent(result, `${id}_${key}`)
    }

    if (Array.isArray(data) && data.length > 0 && data[0].componentName) {
      return data.map((c, i) => renderComponent(c, `${id}_${i}`))
    }
  }

  return data
}

const renderComponent = (data, id) => {
  data.componentName = data.componentName.split('_').join('');
  const {
    componentName,
    componentData,
    componentChildren
  } = data

  const props = Object.keys(componentData).reduce((output, key, i) => {
    let result = findRenderComponent(componentData[key], id)

    return {
      ...output,
      [key]: result,
    }
  }, {})

  var children = [].concat(componentChildren || []).map((c, i) => renderComponent(c, `${id}_${i}`))

  if (!Components[componentName]) {
    throw new Error(`component ${componentName} does not exists, should be one of ${Object.keys(Components).join(', ')}`)
  }

  return React.createElement(Components[componentName], { ...props,
    key: id
  }, children);
}

export const renderPage = data => {

  try {
    const {
      pageTemplate,
      pageContent,
      pageData
    } = data

    const { theme } = pageData;

    const puzzelIds = isProduction() ? prodPuzzelIds : testPuzzleIds;

    Object.keys(pageData).map((k, i) => pageData[k] = findRenderComponent(pageData[k], `root_${k}`))

    return (
      <PuzzelProvider id={puzzelIds[theme]}>
        {React.createElement(
            Components[pageTemplate], {
              data: pageData
            },
            pageContent.map((c, i) => renderComponent(c, `root_${i}`)),
        )}
      </PuzzelProvider>
    )
  } catch (err) {
    console.error(err);
  }
}