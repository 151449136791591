// Component Name : Collapsible
// C1 CMS Name : FoldUdLink

// Component used in : CollapsibleTextArea, CollapseableFAQ

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Components
import Collapsable from './Collapsable';

// Import Icons
import Arrow from '../../../assets/Icons/svg/icon_Arrow_Down_Blue.svg';

// Styled-components
const ArrowUp = styled.img.attrs({
  alt: 'Arrow Up',
  src: Arrow,
})`
  position: relative;
  top: -3px;
  transform: scaleY(-1);
`;

const ArrowDown = styled.img.attrs({
  alt: 'Arrow Down',
  src: Arrow,
})`
  position: relative;
  top: -3px;
`;

const Icon = styled.span`
  position: relative;
  top: 2px;
  padding-left: 10px;
  pointer-events: none;
`;

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  cursor: pointer;
  text-align: center;
  margin-bottom: 24px;
`;

const Block = styled.div`
  width: 100%;
`;

// Component
class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle(e) {
    const { isExpanded } = this.state;
    e.preventDefault();
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  render() {
    const { titleOn, titleOff, children } = this.props;
    const { isExpanded } = this.state;

    return (
      <Container>
        <Header
          onClick={e => this.handleToggle(e)}
          tabIndex="0"
          role="switch"
          aria-checked={isExpanded}
          aria-label="Collapsible Header"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.handleToggle(e);
            }
          }}
        >
          {isExpanded ? titleOn : titleOff}
          <Icon left aria-hidden>
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </Icon>
        </Header>
        <Block>
          <Collapsable collapsed={!isExpanded}>{children}</Collapsable>
        </Block>
      </Container>
    );
  }
}

// Proptypes
Collapsible.propTypes = {
  titleOn: PropTypes.string.isRequired,
  titleOff: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Exports
export default Collapsible;
