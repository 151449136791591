
const STORAGE_PREFIX = '_loginContextCache';

const LoginContextCache = {
    setCache: (value) => {
        global.sessionStorage.setItem(STORAGE_PREFIX, JSON.stringify(value));
    },
    getCache: () => {
        if (typeof window === 'undefined') {
            return null;
        }

        const value = global.sessionStorage.getItem(STORAGE_PREFIX);
        try {
            return value ? JSON.parse(value) : null;
        } catch (err) {
            console.error('Could not read from login context cache', err); // eslint-disable-line no-console
            return null;
        }
    },
    clearCache: () => {
        global.sessionStorage.removeItem(STORAGE_PREFIX);
    }
  }

export default LoginContextCache;