// Import Node Modules
import React from 'react';

// Import Default Prop-Types
import DefaultPropTypes from './DefaultPropTypes';

import { isMobileOnClient } from './helperFunctions';

// Import Components
import { CustomLayout } from './CustomLayout';
import Overlay from './Overlay';

// Import Styled-Components
import { FrontPageWrapper } from './components/Wrappers';

// Template #1
class Template1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobileOnClient(),
    };
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleNavResize);
    this.handleNavResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  render() {
    const { children, data } = this.props;
    const { mobile } = this.state;

    return (
      <CustomLayout data={data}>
        <FrontPageWrapper minWidth="100%">
          {data.bannerImage ? (
            <div>
              {mobile ? (
                <Overlay
                  image={data.mobile != null ? data.mobile : data.bannerImage}
                  content={data.bannerContent}
                  displayMobile={data.mobile != null}
                  mobile
                />
              ) : (
                <Overlay
                  image={data.bannerImage}
                  content={data.bannerContent}
                  mobile={false}
                />
              )}
            </div>
          ) : (
            ''
          )}
          {children}
        </FrontPageWrapper>
      </CustomLayout>
    );
  }
}

// Proptypes
Template1.propTypes = DefaultPropTypes;

export default Template1;
