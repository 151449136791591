import { currentTheme } from '../theme/index';

/* eslint-disable no-multi-spaces, no-multiple-empty-lines */
// Color Guide
export const Primary = currentTheme.PrimaryColor;

export const aquaMedium =     '#bbdced';  // PRIMARY_INACTIVE
export const aquaLight =      '#ddeef6';  // PRIMARY_VARIANT1
export const aquaBright =     '#2aa7e3';  // PRIMARY_LINK

export const darkGray =       '#464543';  // PRIMARY_TEXT
export const midGray =        '#a2a2a1';  // GRAPH_INCOME
export const lightGray =      '#ececec';  // PRIMARY_BACKGROUND
export const border =         '#d0d0d0';  // PRIMARY_BORDER
export const secondaryText =  '#6b6a69';  // SECONDARY_TEXT

export const burgundy =       '#9d1b3a';  // GRAPH_PAYOUTS_VARIANT1
export const pink =           '#ff94a1';  // GRAPH_PAYOUTS_VARIANT2
export const brightPink =     '#fe4d77';  // GRAPH_PAYOUTS_VARIANT3
export const purple =         '#615bb1';  // GRAPH_PAYOUTS_VARIANT4

export const yellow =         '#f1ad3d';  // PRIMARY_WARNING
export const green =          '#66c72a';  // PRIMARY_SUCCESS
export const red =            '#ca3a3a';  // PRIMARY_ALERT

export const skin =           '#f5ab90';
export const turquoise =      '#15b5c0';
export const darkPurple =     '#4c155b';  // dark-purple

// Standard B/W
export const black =          '#000000';  // black
export const white =          '#ffffff';  // SECONDARY_BACKGROUND

// Shadow
const shadowColor = 'rgba(0,0,0,0.1)';
export const shadow = `0 15px 30px 0 ${shadowColor}, 0 5px 10px 0 ${shadowColor}`;

// BackgroundColors
export const PUBLIC_MENU_DROPDOWN =     '#585756';  // Maby "secondaryText"-color can be used
export const PUBLIC_READ_MORE_BACKGROUND =  '#e2e2e2';  // Maby "border"-color can be used
export const FORM_ERROR_BACKGROUND = '#faecec';

// Old colors should not be used
export const aquaNight = darkGray;    // used to be #1f3a54
export const semiBlack = darkGray;    // used to be #464543 (same as DarkGray)
export const bg = lightGray;          // used to be #f2f2f2
export const altBg = border;          // used to be #e9e9e9
export const aqua = currentTheme.PrimaryColor;
