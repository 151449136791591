// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Icon
import DefaultIcon from '../../../../assets/DefaultImages/IconCard.png';
import Arrow from '../../../../assets/Icons/svg/icon_Arrow_Right_Blue.svg';

// Import Guides
import * as ColorGuide from '../../../../cssGuides/colorGuide';

// Styled Component
const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  box-sizing: border-box;
`;

const IconContainer = styled.div`
  min-width: 100px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Icon = styled.img`
  max-width: 64px;
`;

const TextContainer = styled.div`
  flex: 4;
  box-sizing: border-box;
  padding: 40px 20px 40px 0px;
`;

const Header = styled.h2`
  color: ${ColorGuide.darkGray};
  margin-bottom: 16px;
`;

const Text = styled.p`
  margin-bottom: 0px;
  color: ${ColorGuide.darkGray};
`;

const BlueArrow = styled.img`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

// Component
const TextIcon = (props) => {
  const {
    icon, header, text, arrow, altText,
  } = props;
  return (
    <Container>
      <IconContainer>
        <Icon
          src={icon === '' ? DefaultIcon : icon}
          alt={altText}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DefaultIcon;
            e.target.alt = 'Default Image';
          }}
        />
      </IconContainer>
      <TextContainer>
        <Header>{header}</Header>
        <Text>{text}</Text>
      </TextContainer>
      {arrow === true ? <BlueArrow src={Arrow} alt="Arrow right" /> : null}
    </Container>
  );
};

TextIcon.propTypes = {
  /** Header text */
  header: PropTypes.string.isRequired,
  /** Html String */
  text: PropTypes.string.isRequired,
  /** Icon url */
  icon: PropTypes.string,
  /** Arrow pointing right */
  arrow: PropTypes.bool,
  /** Icon Alternate text */
  altText: PropTypes.string,
};

TextIcon.defaultProps = {
  icon: Icon,
  arrow: false,
  altText: 'Icon',
};

export default TextIcon;
