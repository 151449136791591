// Common Component : ColumnsDefined

// Components is used in : MainMenuBar, CookieBanner, InfoTag_Pages

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${props => props.minWidth}) {
    ${({ columns, gap }) => {
    const col = columns.split(' ');
    let cols = '';
    let count = 0;

    for (let i = 0; i < col.length; i += 1) {
      count += 1;

      if (col[i] === 'auto') {
        cols += `& > div:nth-child(${count}n) { flex-grow: 1; margin: 0 ${gap}; };`;
      } else {
        cols += `& > div:nth-child(${count}n) { flex-basis: calc(${col[i]} - ${gap});}`;
      }
    }

    return cols;
  }};
  }
  @media (max-width: ${props => props.minWidth}) {
    flex-basis: 100%;
    & > div {flex-basis: 100%}
  }
`;

// Class
class ColumnsDefined extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      columns,
      minWidth,
      className,
      children,
      gap,
    } = this.props;

    return (
      <FlexContainer
        minWidth={minWidth}
        columns={columns}
        gap={gap}
        className={className}
      >
        {children}
      </FlexContainer>
    );
  }
}

// Proptypes
ColumnsDefined.propTypes = {
  columns: PropTypes.string.isRequired,
  minWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gap: PropTypes.string,
};

ColumnsDefined.defaultProps = {
  minWidth: '762px',
  className: undefined,
  gap: '0px',
};

// Exports
export default ColumnsDefined;


