// Import Node Modules
import styled from 'styled-components';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';
import { currentTheme } from '../../../theme/index';

// Styled-components
export const TableContainer = styled.div`
  overflow-x: auto;
  padding: 0 10px;
`;

export const DesktopTable = styled.table`
  text-align: ${({ classType }) => (classType === 'table_layout_right' && 'right')
    || (classType === 'table_layout_center' && 'center')
    || 'left'};
  width: 100%;
  border-collapse: collapse;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: ${ColorGuide.shadow};
  margin-bottom: 60px;
`;

export const DesktopTH = styled.th`
  height: 80px;
  background-color: ${props => props.theme.PrimaryColor || currentTheme.PrimaryColor};
  color: ${ColorGuide.white};
  padding: 0 20px;
  &:first-child {
    text-align: left;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${ColorGuide.white};
    margin-bottom: 0px;
  }
  em {
    font-size: 12px;
  }
`;

export const TableRow = styled.tr`
  height: 50px;
  background-color: ${ColorGuide.lightGray};
  &:nth-child(odd) {
    background-color: ${ColorGuide.border};
  }
`;

export const TableCell = styled.td`
  padding: 0 20px;
  font-size: 14px;
  p {
    margin-bottom: 0px;
  }
  &:first-child {
      text-align: left;
    }
`;
