// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Button

const commonSizing = css`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

const globalBody = css`
  * {
    ${commonSizing};
    margin: 0px;
    padding: 0px;
    &:before {
      ${commonSizing};
    }
    &:after {
      ${commonSizing};
    }
  }

  html,
  body,
  #___gatsby,
  #___gatsby > div {
    height: 100%;
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: ${ColorGuide.white};
    color: ${ColorGuide.darkGray};
  }
`;

export default globalBody;
