// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';
import { margins } from '../../../cssGuides/commonStyles';
// Styled Components

export const Container = styled.div`
  ${margins};
`;

export const SelectBox = styled.div``;

export const SelectInfoBox = styled.div`
  transition: all 0.3s ease-out;
  height: ${props => (props.visible ? props.height : '0')}px;
  overflow: hidden;
`;

export const SelectInfo = styled.div`
  cursor: pointer;
  padding: 10px 10px;
  border-top: 1px solid black;
  font-size: 14px;

  &:hover {
    color: gray;
  }
`;

export const SelectInfoSub = styled.div`
  cursor: pointer;
  line-height: 20px;
  padding: 0 15px 0 30px;
  font-size: 12px;

  &:hover {
    color: gray;
  }
`;

export const InfoTextBox = styled.div`
  margin-left: 0px;
  ${media.tabletP`
    margin-left: 30px;
  `};
`;

export const Icon = styled.div`
  float: right;
  display:none;
  ${media.tabletP`
    display:block;
  `};
`;
