// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import {
  TableContainer,
  DesktopTable,
  DesktopTH,
  TableRow,
  TableCell,
} from './styledDesktop';

// Classes
const Table = (props) => {
  const { thead, classType, tbody } = props;
  return !thead ? null : (
    <TableContainer role="none">
      <DesktopTable classType={classType}>
        {thead && thead.length > 0 && (
          <thead>
            <TableRow
              {...(thead[0].classType !== ''
                ? { className: thead[0].classType }
                : {})}
            >
              {thead[0].tr.map((header, index) => (
                <DesktopTH
                  {...(header.classType !== ''
                    ? { className: header.classType }
                    : {})}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  dangerouslySetInnerHTML={{ __html: header.value }}
                />
              ))}
            </TableRow>
          </thead>
        )}
        <tbody>
          {tbody.map((body, index) => (
            <TableRow
              {...(body.classType !== '' ? { className: body.classType } : {})}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {body.tr.map((row, index2) => (
                <TableCell
                  {...(row.classType !== ''
                    ? { className: row.classType }
                    : {})}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index2}
                  dangerouslySetInnerHTML={{ __html: row.value }}
                />
              ))}
            </TableRow>
          ))}
        </tbody>
      </DesktopTable>
    </TableContainer>
  );
};


// Proptypes
Table.propTypes = {
  classType: PropTypes.string,
  thead: PropTypes.arrayOf(
    PropTypes.shape({
      classType: PropTypes.string,
      tr: PropTypes.arrayOf(
        PropTypes.shape({
          classType: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  tbody: PropTypes.arrayOf(
    PropTypes.shape({
      classType: PropTypes.string,
      tr: PropTypes.arrayOf(
        PropTypes.shape({
          classType: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

Table.defaultProps = {
  classType: undefined,
};

export default Table;
