import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const lottieAsync = import('lottie-web');

const Lottie = ({
  elementType: ElementType,
  data,
  url,
  loop,
  autoplay,
  renderer,
  loader,
  ...props
}) => {
  const element = createRef();
  const [lottie, setLottie] = useState();
  useEffect(() => {
    if (element.current && lottie) {
      lottie.loadAnimation({
        container: element.current,
        renderer,
        loop,
        autoplay,
        animationData: data,
        path: url,
      });
    }
  }, [element, renderer, loop, autoplay, data, url, lottie]);
  lottieAsync.then(setLottie);

  return (
    <ElementType>
      {!lottie && loader}
      <div ref={element} {...props} />
    </ElementType>
  );
};

Lottie.propTypes = {
  elementType: PropTypes.string,
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  url: PropTypes.string,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  renderer: PropTypes.string,
  loader: PropTypes.node,
};

Lottie.defaultProps = {
  elementType: 'div',
  data: undefined,
  url: undefined,
  loop: false,
  autoplay: true,
  renderer: 'svg',
  loader: undefined,
};

export default Lottie;
