// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Import Common Components
import { Wrapper } from '../../../CommonComponents';

// Import Styled-Components
import {
  MenuContainer,
  MenuList,
  MenuItem,
  MenuItemUnderline,
  LogOut,
  ContentContainer as MenuContentContainer,
} from '../StyledComponents/MainMenu';

// Import Icon
import Lock from '../../../../assets/Icons/svg/icon_Menubar_Lock.svg';

// Import Variables
import * as Variables from '../Variables';
import { Consumer as LoginContext } from '../../../../Context/loginContext';

// Class Component - MainMenu
const MainMenu = (props) => {
  const {
    size,
    menu,
    ActiveMenu,
    navHeight,
    collapseNav,
    showNav,
    changeMenu,
  } = props;

  if (navHeight === Variables.navBar) {
    return (
      <MenuContainer collapse={collapseNav} role="none">
        <MenuContentContainer showNav={showNav}>
          <Wrapper minWidth={size}>
            <MenuList role="menu" aria-label="Main Menu Bar">
              {menu.menus.subMenus.map(subMenu => (
                <MenuItem
                  onClick={() => changeMenu(`${subMenu.id}`)}
                  item={subMenu.id}
                  isActive={ActiveMenu}
                  key={subMenu.id}
                  role="menuitem"
                >
                  {subMenu.internal !== true ? (
                    <a href={subMenu.url} style={{ display: 'block' }}>
                      {subMenu.name}
                    </a>
                  ) : (
                    <Link to={subMenu.url} style={{ display: 'block' }}>
                      {subMenu.name}
                    </Link>
                  )}

                  <MenuItemUnderline item={subMenu.id} isActive={ActiveMenu} />
                </MenuItem>
              ))}
              <LoginContext>
                {({ isLoggedIn, homeUrl, logout }) => (
                  isLoggedIn ? (
                    <>
                      <MenuItem>
                        <a
                          href={`/classic#url=${homeUrl}%3Fcachebuster=${new Date().getTime()}`}
                          style={{ display: 'block' }}
                        >
                          Dine tal
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <LogOut
                          role="button"
                          onClick={logout}
                          style={{ display: 'block', border: 'none', cursor: 'pointer' }}
                        >
                          <img
                            src={Lock}
                            width="8"
                            style={{ marginRight: '7.5px' }}
                            alt="LoggedIn"
                          />
                          Log ud
                        </LogOut>
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      style={{ borderLeft: 'none', padding: '7px 0 0 0' }}
                    />
                  )
                )}
              </LoginContext>
            </MenuList>
          </Wrapper>
        </MenuContentContainer>
      </MenuContainer>
    );
  }
  return null;
};

// Proptypes
MainMenu.propTypes = {
  size: PropTypes.string.isRequired,
  menu: PropTypes.shape({
    searchUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    menus: PropTypes.shape({
      subMenus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        display: PropTypes.string,
        internal: PropTypes.bool,
        menuItems: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })),
      })).isRequired,
    }).isRequired,
  }).isRequired,
  ActiveMenu: PropTypes.string.isRequired,
  navHeight: PropTypes.number.isRequired,
  collapseNav: PropTypes.bool.isRequired,
  showNav: PropTypes.bool.isRequired,
  changeMenu: PropTypes.func.isRequired,
};

export default MainMenu;
