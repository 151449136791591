// Component Name : NewsCard
// C1 CMS Name : NewsArchive

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import News from './CardContent/news';

// Component
const NewsCard = (props) => {
  const {
    url,
    image,
    imageMobile,
    timestamp,
    header,
    text,
    imageAltText,
  } = props;
  return (
    <Card shadow maxWidth="100%" minHeight="100%" url={url}>
      <News imageRight={false} image={image} imagemobile={imageMobile} imageAltText={imageAltText} timestamp={timestamp} header={header} manchet={text} />
    </Card>
  );
};

// Proptypes
NewsCard.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
  imageMobile: PropTypes.string,
};

NewsCard.defaultProps = {
  image: '',
  imageAltText: 'Image No Description',
  imageMobile: '',
};

export default NewsCard;
