// Common Component : FullWrapper

// Components is used in : NavBarComponent, CustomLayout

// Import Node Modules
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

const FullMediaQuery = css`
  padding: 0px;
  ${media.tabletL`
    padding: 0px 20px 20px 20px;
  `}
`;

const FullSideMediaQuery = css`
  padding: 0px;
  ${media.tabletL`
    padding: 0px 20px;
  `}
`;

// Styled Components
const FullWrapperDiv = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  ${FullMediaQuery};
`;

const FullWrapperSideDiv = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto; 
  ${FullSideMediaQuery};
`;

// Components
export const FullWrapper = (props) => {
  const { children, className } = props;
  return <FullWrapperDiv className={className}>{children}</FullWrapperDiv>;
};

export const FullWrapperSide = (props) => { // eslint-disable-line react/no-multi-comp
  const { children, className } = props;
  return <FullWrapperSideDiv className={className}>{children}</FullWrapperSideDiv>;
};

// Proptypes
FullWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FullWrapperSide.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FullWrapper.defaultProps = {
  className: undefined,
};

FullWrapperSide.defaultProps = {
  className: undefined,
};
