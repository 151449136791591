// Component Name : ImageCard
// C1 CMS Name : BilledeElement

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import BgImage from './CardContent/bgImage';

// Component
const ImageCard = (props) => {
  const {
    url,
    image,
    text,
    manchet,
    imageAltText,
  } = props;

  return (
    <Card shadow maxWidth="388px" minHeight="100%" url={url}>
      <BgImage
        image={image}
        altText={imageAltText}
        arrow
        header={text}
        text={manchet}
      />
    </Card>
  );
};

// Proptypes
ImageCard.propTypes = {
  url: PropTypes.string,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  manchet: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
};

ImageCard.defaultProps = {
  url: '',
  imageAltText: '',
};

export default ImageCard;
