// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Button

const globalHelperClasses = css`
  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .pull-left {
    float: left !important;
  }

  .pull-right {
    float: right !important;
  }

  .btn-bottom-right {
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 25px;
      right: 25px;
    }
    @media screen and (min-width: 1024px) {
      bottom: 0px;
      right: 40px;
    }
  }

  .btn-bottom-left {
    margin-bottom: 0px;
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 25px;
      left: 25px;
    }
    @media screen and (min-width: 1024px) {
      bottom: 40px;
      left: 40px;
    }
  }

  .btn-center {
    margin: 0 auto;
  }

  .colorWhite {
    color: ${ColorGuide.white};
  }

  .colorDark {
    color: ${ColorGuide.darkGray};
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  img {
    max-width: 100%;
  }

  iframe {
    width: 100%;
  }
`;

export default globalHelperClasses;
