// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Components
import { Columns } from '../../../CommonComponents/index';

// Import Icon
import DefaultImage from '../../../../assets/DefaultImages/WideCard.png';
import DefaultImageMobile from '../../../../assets/DefaultImages/WideCardMobile.png';

// Styled Component

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-bottom: -4px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
`;

class Wide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      desktopPicture: this.props.image,
      // eslint-disable-next-line react/destructuring-assignment
      mobilePicture: this.props.imageMobile,
    };
  }

  // || props.imageMobile !== state.mobilePicture
  // static getDerivedStateFromProps(props, state) {
  //   if (props.image !== state.desktopPicture) {
  //     return {
  //       desktopPicture: props.image,
  //       mobilePicture: props.mobilePicture,
  //     };
  //   }
  //   return null;
  // }

  render() {
    const {
      image,
      imageMobile,
      text,
      imageRight,
      altText,
    } = this.props;

    const { desktopPicture, mobilePicture } = this.state;

    const ImageSide = (
      <ImageContainer>
        <picture
          key={desktopPicture}
          onError={(e) => {
            e.target.onerror = null;
            this.setState({
              desktopPicture: '',
              mobilePicture: '',
            });
          }}
        >
          <source
            media="(max-width: 500px)"
            srcSet={mobilePicture === '' ? DefaultImageMobile : imageMobile}
          />
          <source
            media="(min-width: 500px)"
            srcSet={desktopPicture === '' ? DefaultImage : image}
          />
          <img
            src={desktopPicture === '' ? DefaultImage : image}
            alt={altText}
            style={{ width: '100%' }}
          />
        </picture>
      </ImageContainer>
    );

    const TextSide = (
      <TextContainer dangerouslySetInnerHTML={{ __html: text }} />
    );

    return (
      <Container>
        <Columns flex={1} disableWrap columns={2} minWidth="768px">
          {imageRight === true ? TextSide : ImageSide}
          {imageRight === true ? ImageSide : TextSide}
        </Columns>
      </Container>
    );
  }
}

Wide.propTypes = {
  /** Html String */
  text: PropTypes.node.isRequired,
  /** Image url */
  image: PropTypes.string,
  /** Mobile Image url */
  imageMobile: PropTypes.string,
  /** Image Alt Text */
  altText: PropTypes.string,
  /** What side is image displayed */
  imageRight: PropTypes.bool,
};

Wide.defaultProps = {
  image: DefaultImage,
  imageMobile: DefaultImageMobile,
  imageRight: true,
  altText: 'Alt text is missing',
};

export default Wide;
