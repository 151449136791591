// Import Node Modules
import { css } from 'styled-components';

// Media Query Variables
import { media } from './MediaQuerys';

// Common styles

export const margins = css`
  margin: 0 auto 10px auto;
  ${media.tabletP`
    margin: 0 auto 40px auto;
  `}
  ${media.tabletL`
    margin: 0 auto 40px auto;
  `}
  ${media.desktop`
    margin: 0 auto 60px auto;
  `}
`;

export const SPACINGS = {
  xxl: 40,
  xl: 32,
  lg: 24,
  md: 16,
  m: 12,
  ms: 10,
  sm: 8,
  s: 6,
  xs: 4,
};

// Exports
export default margins;
