// Common Component : Url & OnClickUrl

// Component used in : Skideshow, Footer.

// Import Node Modules
import React from 'react';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';

// Url Component
export const Url = (props) => {
  const { url, name, children } = props;

  if (url.indexOf('http') === 0) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {name || children}
      </a>
    );
  }
  return <Link to={url}>{name || children}</Link>;
};

export function OnClickUrl(url) {
  if (url.indexOf('http') === 0) {
    global.window.open(url, '_blank');
  } else {
    navigate(url);
  }
}

// Proptypes
Url.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
};

Url.defaultProps = {
  name: undefined,
  children: undefined,
};
