// Import Node Modules
import React from 'react';

import { isMobileOnClient } from '../../../template/helperFunctions';

// Import Components
import Table from './desktop';
import Mobile from './mobile';

// Component
class HtmlTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobileOnClient(),
    };
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleNavResize);
    this.handleNavResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  render() {
    const { mobile } = this.state;
    return (
      <div role="none">
        {mobile ? <Mobile {...this.props} /> : <Table {...this.props} /> }
      </div>
    );
  }
}

// Exports
export default HtmlTable;
