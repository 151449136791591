// Import Node Modules
import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

// Import Components
import { AlignContentCenter } from '../../../CommonComponents';

import {
  Logo,
  LogOut,
  SearchIcon,
  ImgIcon,
  MenuBarContainerMobile,
  MenuBarContainerDesktop,
  ButtonContainer,
  HamburgerContainer,
  ChildContainer,
  Profile,
  ProfileName,
} from '../StyledComponents/MainMenuBar';

import { isMobileOnClient } from '../../../../template/helperFunctions';

import { LoginBtnComponent } from './Login';

import Icon from '../../../../assets/Icons/svg/icon_Search_White.svg';
import Avatar from '../../../../assets/Icons/svg/icon_Menubar_Profile.svg';

// Import Context
import { Consumer as LoginContext } from '../../../../Context/loginContext';

// Class
class MainMenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobileOnClient(),
    };
  }

  componentDidMount() {
    this.setState({ isClient: true });
    global.addEventListener('resize', this.handleNavResize);
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize = () => {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  renderLogin(x, y) {
    const { mobile } = this.state;
    const { theme } = this.props;

    return (
      <LoginContext>
        {({ isLoggedIn, userName, profileUrl, logout }) =>
          isLoggedIn ? (
            <AlignContentCenter>
              {mobile ? (
                <div
                  style={{
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: `${y}px`,
                  }}
                >
                  <LogOut
                    role="button"
                    onClick={logout}
                  >
                    Log ud
                  </LogOut>
                </div>
              ) : (
                <div 
                  style={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: `${y}px`,
                  }}
                >
                  <Profile src={Avatar} />
                  <Link
                    to={`/classic#url=${profileUrl}%3Fcachebuster=${new Date().getTime()}`}
                    style={{ display: 'block' }}
                  >
                    <ProfileName>{userName}</ProfileName>
                  </Link>
                </div>
              )}
            </AlignContentCenter>
          ) : (
            <LoginBtnComponent to={x}>{theme.BtnName}</LoginBtnComponent>
          )
        }
      </LoginContext>
    );
  }

  render() {
    const { mobile, isClient } = this.state;
    const { children, menu } = this.props;

    if (mobile) {
      return (
        <MenuBarContainerMobile key={isClient}>
          <Logo imgSize={50} />
          <ButtonContainer navHeight={50}>
            {this.renderLogin(menu.loginUrl, 50)}
            <HamburgerContainer>{children}</HamburgerContainer>
          </ButtonContainer>
        </MenuBarContainerMobile>
      );
    }

    return (
      <MenuBarContainerDesktop key={isClient}>
        <Logo imgSize={100} />
        <ChildContainer>{children}</ChildContainer>
        <ButtonContainer navHeight={100}>
          <SearchIcon role="img" aria-label="Search Icon" imgSize={100}>
            {menu.searchUrl !== '' && (
              <AlignContentCenter>
                <Link to={menu.searchUrl} style={{ width: '20px' }}>
                  <ImgIcon src={Icon} />
                </Link>
              </AlignContentCenter>
            )}
          </SearchIcon>
          {menu.loginUrl !== '' && this.renderLogin(menu.loginUrl, 100)}
        </ButtonContainer>
      </MenuBarContainerDesktop>
    );
  }
}

// Proptypes
MainMenuBar.propTypes = {
  theme: PropTypes.object.isRequired, // eslint-disable-line
  children: PropTypes.node.isRequired,
  menu: PropTypes.shape({
    loginUrl: PropTypes.string,
    searchUrl: PropTypes.string,
    menus: PropTypes.shape({
      subMenus: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
          internal: PropTypes.bool,
          name: PropTypes.string,
          url: PropTypes.string,
          menuItems: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            })
          ),
        })
      ),
    }),
  }).isRequired,
};

// Exports
export default withTheme(MainMenuBar);
