// Import Node Modules
import styled from 'styled-components';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';
import { margins } from '../../../cssGuides/commonStyles';

// Styled Components - Icon
export const ArrowUp = styled.img`
  position: relative;
  top: -3px;
  transform: scaleY(-1);
`;

export const ArrowDown = styled.img`
  position: relative;
  top: -3px;
`;

export const Icon = styled.span`
  position: absolute;
  right: 20px;
  pointer-events: none;
`;

// Styled Components - Collapsible
export const Container = styled.div`
position:relative;
  width: 100%;
  border-top: 1px solid ${ColorGuide.lightGray};
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid ${ColorGuide.lightGray};
  }
`;

export const Header = styled.h3`
  width: 100%;
  padding: 15px 0px 15px 20px;
  &:hover {
    background-color: ${ColorGuide.border};
  }
`;

export const Block = styled.div`
  width: 100%;
`;

export const BlockContent = styled.div`
  height: 100%;
  padding: 0px 0px 15px 20px;
  p {
    margin-top: 15px;
    margin-bottom: 0px;
  }
`;

// Styled Components - CollapsableFAQ
export const CollapseContainer = styled.div`
  ${margins};
`;

export const OutsideHeader = styled.h2`
  text-align: center;
`;
