// Import Node Modules
import styled from 'styled-components';

// Import Variables
import * as Variables from '../Variables';
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { media } from '../../../../cssGuides/MediaQuerys';

// Styled-components
export const NavBarContainer = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: top 0.5s;
  max-height: ${Variables.level2};
`;

export const NavBar = styled.div`
  background-color: ${ColorGuide.darkGray};
  display: block;
  width: 100%;
  transition: all 0.3s;
  height: ${Variables.level1}px;

  ${media.tabletP`
    height: ${props => (props.collapse ? '120px' : '150px')};
  `}
`;

export const ClearSpaceBehindNav = styled.div`
  height: 50px;
  ${media.tabletP`
    height: 150px;
  `}
`;

export const ColorBar = styled.div.attrs({
  role: 'none',
})`
  width: 100%;
  height: 3px;
  background-color: ${props => props.theme.PrimaryColor};
  ${media.tabletP`height: 8px;`};
`;
