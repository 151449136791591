// Common Component : Wrapper

// Import Node Modules
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

// Variables
import { currentTheme } from '../../../theme/index';
import GlobalStyle from '../../../cssGuides/GlobalStyles/index';

const size = (props) => {
  return props.size ? props.size : '1024px';
};

// Class
const Wrapper = styled.div`
  @media (min-width: ${size}) {
    width: ${size};
    margin: 0 auto;
    padding: 60px 60px ${props => (props.paddingBottom ? props.paddingBottom : '0.1px')} 60px;
  }
  @media (max-width: ${size}) {
    width: 100%;
    padding:10px;
  }
  flex: 1;
  background-color: ${props => (props.bg ? props.bg : currentTheme.PrimaryHover)};

 

  box-sizing: border-box;
`;

const StoryWrapper = (props) => {
  const {
    children,
    width,
    paddingBottom,
    bg,
  } = props;

  return (
    <ThemeProvider theme={currentTheme}>
      <div>
        <GlobalStyle />
        <Wrapper minWidth={width} paddingBottom={paddingBottom} bg={bg}>
          {children}
        </Wrapper>
      </div>
    </ThemeProvider>
  );
};

// Proptypes
StoryWrapper.propTypes = {
  width: PropTypes.string.isRequired,
  paddingBottom: PropTypes.string,
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
};

StoryWrapper.defaultProps = {
  paddingBottom: '0.1px',
  bg: currentTheme.PrimaryHover,
};

// Exports
export default StoryWrapper;
