import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Columns } from '../../CommonComponents';
import {
  SelectBox,
  Button,
  Search,
  Post,
  Dato,
  CatContainer,
  Cat,
  SearchContainer,
} from './styled';

const EsgTracker = ({
  categories,
  esg,
}) => {
  const [selectData, setSelectData] = useState();
  const [showData, setShowData] = useState();

  const [category, setCategory] = useState('all');
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    const selectedData = esg.filter((item) => {
      if (category === 'all') {
        return esg;
      }
      return item.category.includes(category);
    });

    const showingData = selectedData.filter((item) => {
      return (
        item.header.includes(searchString)
        || item.content.includes(searchString)
      );
    });

    setSelectData(selectedData);
    if (searchString.length === 0 || searchString.length > 2) {
      setShowData(showingData);
    }
  }, [searchString, category]);

  return (
    <div className="App">
      <header className="App-header">
        <Columns columns={2}>
          <div>
            <SelectBox
              value={category}
              onChange={event => setCategory(event.target.value)}
            >
              <option value="all">Vis alle</option>
              {categories.map((item) => {
                return <option key={item} value={item}>{item}</option>;
              })}
            </SelectBox>
          </div>
          <SearchContainer>
            <Button onClick={() => setSearchString('')}>
              Nulstil søgning
            </Button>
            <Search
              type="text"
              placeholder="Søg"
              value={searchString}
              onChange={event => setSearchString(event.target.value)}
            />
          </SearchContainer>
        </Columns>
        <br />
        {selectData !== undefined
          && showData.map((item) => {
            return (
              <Post key={item.id}>
                <Dato>{item.date}</Dato>
                <CatContainer>
                  {item.category.map((cat) => {
                    if (cat === category) {
                      return (
                        <Cat key={`show_${cat}`} style={{ fontWeight: '900' }}>
                          {cat}
                        </Cat>
                      );
                    }
                    return <Cat key={`show_${cat}`}>{cat}</Cat>;
                  })}
                </CatContainer>
                <h3>{item.header}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </Post>
            );
          })}
      </header>
    </div>
  );
};

// Proptypes
EsgTracker.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  esg: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      category: PropTypes.arrayOf(PropTypes.string).isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EsgTracker;
