// Component Name : AlignContentCenter

// Component is used in : Footer, Login button, MainMenuBar (Component, Styled-Component), Banner, Template Overlay.

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styled Components
const Align = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// Component
const AlignContentCenter = (props) => {
  const { className, children } = props;
  return <Align className={className}>{children}</Align>;
};

// Proptypes
AlignContentCenter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AlignContentCenter.defaultProps = {
  className: undefined,
};

// Exports
export default AlignContentCenter;
