// Component : DesktopTools

// Import Node Modules
import React, { Component } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import ChatView from './ChatView';

// Import Icons
import chatIconBlack from '../../../assets/Icons/png/sticky_chat_black.png';
import ringIconBlack from '../../../assets/Icons/png/sticky_phone_black.png';
import postIconBlack from '../../../assets/Icons/png/sticky_post_black.png';
import chatIconWhite from '../../../assets/Icons/png/sticky_chat_white.png';
import ringIconWhite from '../../../assets/Icons/png/sticky_phone_white.png';
import postIconWhite from '../../../assets/Icons/png/sticky_post_white.png';

import {
  ToolsContainer,
  Container,
  Wrapper,
  Content,
  NavLinks,
  BtnContainer,
  CloseBtn,
  Servicemeddelelse,
  ServicemeddelelseContent,
} from './styledcomponents';

class DesktopTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      module: null,
      menu: true,
      isClient: false,
    };
    this.endPage = this.endPage.bind(this);
  }

  componentDidMount() {
    this.setState({ isClient: true })
    global.addEventListener('scroll', this.endPage);

    // Animation between screens
    const links = global.document.querySelectorAll('.itemLinks');
    const wrapper = global.document.querySelector('#wrapper');

    function removeActiveLinks() {
      for (let i = 0; i < links.length; i += 1) {
        links[i].classList.remove('active');
      }
    }

    // vendor prefix management
    function getSupportedPropertyName(properties) {
      for (let i = 0; i < properties.length; i += 1) {
        if (typeof global.document.body.style[properties[i]] !== 'undefined') {
          return properties[i];
        }
      }
      return null;
    }

    const transforms = [
      'transform',
      'msTransform',
      'webkitTransform',
      'mozTransform',
      'oTransform',
    ];

    const transformProperty = getSupportedPropertyName(transforms);

    function changePosition(link) {
      const position = link.getAttribute('data-pos');
      const translateValue = `translate3d(${position}, 0px, 0)`;
      wrapper.style[transformProperty] = translateValue;

      link.classList.add('active');
    }

    function setClickedItem(e) {
      removeActiveLinks();
      const clickedLink = e.target;
      changePosition(clickedLink);
    }

    for (let i = 0; i < links.length; i += 1) {
      const link = links[i];
      link.addEventListener('click', setClickedItem, false);
      link.itemID = i;
    }
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.endPage);
  }

  handleToggle(e) {
    let setVar = false;
    let mod = null;
    const { module, visible } = this.state;

    if (e === module) {
      setVar = !visible;
      mod = null;
    } else {
      setVar = true;
      mod = e;
    }

    this.setState({
      module: mod,
      visible: setVar,
    });
  }

  endPage() {
    if (
      global.window.innerHeight + global.window.scrollY
      >= global.document.body.scrollHeight - 175
    ) {
      this.setState({
        menu: false,
      });
    } else {
      this.setState({
        menu: true,
      });
    }
  }

  render() {
    const { data, pos } = this.props;
    const { visible, menu, module, isClient } = this.state;

    let startDate;
    let slutDate;
    const toDay = new Date();

    if (data.serviceMessage !== undefined) {
      startDate = new Date(data.serviceMessage.start);
      slutDate = new Date(data.serviceMessage.end);
    }

    return (
      <ToolsContainer role="menu" aria-label="Sticky Buttons" pos={pos} key={isClient}>
        <Container id="contentContainer" visible={visible} role="none">
          <Wrapper id="wrapper" role="none">
            {data.ring && (
              <Content id="itemTwo" role="dialog" aria-label="Ring til os">
                <div dangerouslySetInnerHTML={{ __html: data.ring.info }} />
                <CloseBtn onClick={() => this.handleToggle('Ring')}>
                  &#x254B;
                </CloseBtn>
              </Content>
            )}
          </Wrapper>
        </Container>

        <NavLinks id="navLinks" visible={visible} menu={menu} role="none">
          <ul role="none">
            <ChatView>
              {/* if chat is visible all icons are hidden */}
              {({ status: chatStatus, onClickOpen, visible: chatVisible }) => !chatVisible && (
                <>
                  {data.serviceMessage && startDate <= toDay && slutDate >= toDay && (
                    <Servicemeddelelse key="service-message">
                      <ServicemeddelelseContent
                        dangerouslySetInnerHTML={{ __html: data.serviceMessage.text }}
                      />
                    </Servicemeddelelse>
                  )}
                  {data.chat && chatStatus === 'open' && (
                    <li
                      className="itemLinks"
                      onClick={onClickOpen}
                      role="none"
                      key={`chat-${chatStatus}`}
                    >
                      <BtnContainer
                        show={module}
                        module="Chat"
                        role="menuitem"
                        aria-label="Chat med os"
                      >
                        <img
                          src={module === 'Chat' ? chatIconBlack : chatIconWhite}
                          alt="Chat med os"
                        />
                        <br />
                        {data.chat.text}
                      </BtnContainer>
                    </li>
                  )}
                  {data.ring && (
                    <li
                      className="itemLinks"
                      data-pos="0px"
                      onClick={() => this.handleToggle('Ring')}
                      role="none"
                      key={`ring-${chatStatus}`}
                    >
                      <BtnContainer
                        show={module}
                        module="Ring"
                        role="menuitem"
                        aria-label="Ring til os"
                      >
                        <img
                          src={module === 'Ring' ? ringIconBlack : ringIconWhite}
                          alt="Ring til os"
                        />
                        <br />
                        {data.ring.text}
                      </BtnContainer>
                    </li>
                  )}
                  {data.bestil && (
                    <li
                      className="itemLinks"
                      onClick={() => navigate(data.bestil.url)}
                      role="none"
                      key={`bestil-${chatStatus}`}
                    >
                      <BtnContainer
                        show={module}
                        module="Bestil"
                        role="menuitem"
                        aria-label="Skriv til os"
                      >
                        <img
                          src={module === 'Bestil' ? postIconBlack : postIconWhite}
                          alt="Skriv til os"
                        />
                        <br />
                        {data.bestil.text}
                      </BtnContainer>
                    </li>
                  )}
                </>
              )}
            </ChatView>
          </ul>
        </NavLinks>
      </ToolsContainer>
    );
  }
}

DesktopTools.propTypes = {
  pos: PropTypes.string,
  data: PropTypes.shape({
    bestil: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    chat: PropTypes.shape({
      offlineText: PropTypes.string,
      text: PropTypes.string,
    }),
    ring: PropTypes.shape({
      info: PropTypes.string,
      text: PropTypes.string,
    }),
    serviceMessage: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
};

DesktopTools.defaultProps = {
  pos: 'fixed',
};

export default DesktopTools;
