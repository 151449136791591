// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Paragraph

const common = css`
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const globalParagraph = css`
  p {
    ${common};
    line-height: 1.38;
    letter-spacing: -0.3px;
    font-weight: 400;
    margin-bottom: 24px;

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }
  }

  p.intro {
    ${common};
    letter-spacing: 0.4px;
    font-weight: 400;

    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 20px;
    }
  }

  p.manchet,
  p.Manchet {
    ${common};
    letter-spacing: 0.4px;
    font-weight: 400;
    padding-bottom: 30px;
    border-bottom: 1px solid ${ColorGuide.darkGray};
    text-align: center;

    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 20px;
    }
  }

  p.body {
    ${common};
    line-height: 1.38;
    letter-spacing: -0.3px;
    font-weight: 400;

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }
  }

  p.bold {
    ${common};
    line-height: 1.38;
    letter-spacing: -0.4px;
    font-weight: 700;

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }
  }

  p.small {
    ${common};
    line-height: 1.47;
    font-weight: 400;

    font-size: 10px;
    @media screen and (min-width: 768px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 13px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 14px;
    }
  }

  p.footnote {
    ${common};
    color: ${ColorGuide.midGray};
    font-weight: 400;

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 13px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 14px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 14px;
    }
  }
`;

export default globalParagraph;
