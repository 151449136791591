// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Button

const commonButton = css`
  text-align: center;
  padding: 16px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  @media screen and (min-width: 800px) {
    min-width: 232px;
    width: auto;
  }
`;

const globalButton = css`
  .primaryBtn,
  .linkBtn {
    ${commonButton};
    background-color: ${props => props.theme.PrimaryColor};
    border: none;
    color: ${ColorGuide.white} !important;
    &:hover {
      background-color: ${props => props.theme.PrimaryHover};
    }
  }

  .primary2Btn {
    ${commonButton};
    background-color: ${ColorGuide.darkGray};
    color: ${ColorGuide.white} !important;
    &:hover {
      background-color: ${ColorGuide.black};
    }
  }
  
  .secondaryBtn {
    ${commonButton};
    background-color: ${ColorGuide.white};
    border: 1px solid ${props => props.theme.PrimaryColor};
    color: ${props => props.theme.PrimaryColor} !important;
    &:hover {
      background-color: ${ColorGuide.lightGray};
    }
  }
`;

export default globalButton;
