// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// Import Icons
import WarningIcon from '../../assets/Icons/svg/icon_Warning_Error.svg';

// CSS Common
const common = css`
  padding: 13px 16px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
`;

const placeholder = css`
  &::placeholder {
    opacity: 0.3;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: ${ColorGuide.darkGray};
  }
`;

const check = css`
  border: 1px solid ${ColorGuide.white};
  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.PrimaryColor};
    border-radius: 4px;
    outline-offset: 0;
  }
`;

// CSS Form

const globalForm = css`
  .formContainer {
    background-color: ${ColorGuide.lightGray};
    padding: 10px;
    border-radius: 7px;
    @media screen and (min-width: 768px) {
      padding: 40px 200px;
    }
  }

  .errorBox {
    position: relative;
    width: 100%;
    border: solid 1px ${ColorGuide.red};
    background-color: ${ColorGuide.FORM_ERROR_BACKGROUND};
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 40px 10px 10px 40px;

    &:before {
      content: url('${WarningIcon}');
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .errorText {
    position: absolute;
    left: 40px;
    top: 12px;
    color: ${ColorGuide.red};
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  .required {
    &:after {
      color: ${ColorGuide.red};
      content: ' *';
    }
  }

  .btnContainer {
    width: 100%;
    text-align: center;
  }

  input[type='submit'],
  input[type='reset'] {
    ${common};
    background-color: ${props => props.theme.PrimaryColor};
    color: ${ColorGuide.white};
    margin-right: 20px;
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
    input[type='password'] {
    ${common};
    ${placeholder};
    ${check};
    background-color: ${ColorGuide.white};
    width: 100%;
  }

  select {
    ${common};
    ${check};
    width: 100%;
    height: 46px;

  }
`;
export default globalForm;
