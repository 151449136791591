// Import Node Modules
import styled, { css } from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';

// CSS
const heights = css`
  height: 550px;

  ${media.tabletP`
    height: 360px;
  `};
  ${media.tabletL`
    height: 490px;
  `};
  ${media.desktop`
    height: 560px;
  `};
`;

// Icons
export const Dashes = styled.span`
  font-size: 50px;
  padding: 5px;
`;

// Styled Components
export const Container = styled.section`
  padding: 50px 0px;
  background-color: ${ColorGuide.white};
  padding: 20px 0px;

  ${media.desktop`
    padding: 50px 0px;
  `};
`;

export const Header = styled.h2`
  text-align: center;
  margin-bottom: 17px;
  ${media.desktop`
      font-size:50px;
  `};
`;

export const Manchet = styled.p`
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;

  ${media.tabletL`
    font-size: 16px;
    margin-bottom: 30px;
  `};
  ${media.desktop`
    font-size: 20px;
    margin-bottom: 47px;
  `};
`;

// Styled Components CarouselUI
export const CarouselUIContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${media.desktop`
    margin:0 auto;
  `};
`;

export const Children = styled.div`
  width: 100%;
  position: relative;
  ${heights};
`;

const ArrowCommon = css`
  z-index: 10;
  position: absolute;
  margin-top: 245px;
  cursor: pointer;
  opacity: 0.9;
`;

export const ArrowLeft = styled.img`
  display: none;
  ${media.desktop`
    display: block;
    ${ArrowCommon};
    left: 40px;
    transform: rotate(180deg);
  `};
`;

export const ArrowRight = styled.img`
  display: none;
  ${media.desktop`
    display: block;
    ${ArrowCommon};
    right: 40px;

  `};
`;

export const Dots = styled.div`
  text-align: center;
  width: 100%;
  z-index: 100;
`;

export const Dot = styled.span`
  font-size: 1.5em;
  cursor: pointer;
  user-select: none;
  margin: 0 10px;
`;

// Styled Components Carousel Slide
export const SlideContainer = styled.div`
  margin: 0 15px;

  ${heights};

  ${media.desktop`
    margin: 0 150px;
  `};
`;

export const SlideContent = styled.div`
  background-color: ${ColorGuide.white};
  position: relative;
  height: 100%;
  box-shadow: ${ColorGuide.shadow};
  border-radius: 10px;
  overflow: hidden;

  & > div {
    height: 100%;
  }
`;
