
import ReactDOM from 'react-dom';
import { renderPage } from './template/RenderPage';
// eslint-disable-next-line import/no-extraneous-dependencies
import components from './components';

export default {
  components,
  render: () => {
    const dataElement = global.document.querySelector('script[type="text/react-data"]');
    const data = JSON.parse(dataElement.innerHTML);
    const rootElement = global.document.createElement('div');
    rootElement.classList.add('react-root');
    global.document.body.appendChild(rootElement);
    ReactDOM.render(
      renderPage(data),
      rootElement,
    );
  },
};
