// Common Component : DivContainer

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Class
const DivContainer = (props) => {
  const { children } = props;
  return <div>{children}</div>;
};

// Proptypes
DivContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

// Exports
export default DivContainer;
