// Common Component : Youtube Video and Text

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Guide
import { media } from '../../../cssGuides/MediaQuerys';

// Classes
export const YoutubeVideo = (props) => {
  const { embed } = props;

  return (
    <div role="none">
      <div
        role="region"
        aria-label="Youtube Video"
        className="embed-responsive-16by9"
        dangerouslySetInnerHTML={{ __html: embed }}
      />
    </div>
  );
};

const Container = styled.div`
  padding-left: 30px;
`;

const Category = styled.p`
  margin-bottom: 25px;
  margin-top:20px;
  ${media.tabletP`

  `}
  ${media.tabletL`

  `}
  ${media.desktop`
    margin-top:0px;
  `}
`;

export const YoutubeText = (props) => { // eslint-disable-line react/no-multi-comp
  const { text } = props;

  return (
    <Container>
      <Category className="footnote">VIDEO</Category>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
};

// Proptypes
YoutubeVideo.propTypes = {
  embed: PropTypes.string.isRequired,
};

YoutubeText.propTypes = {
  text: PropTypes.string.isRequired,
};
