import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const CookieContext = createContext({
  given: {},
});

// Based on category list from
// https://support.cookieinformation.com/hc/en-us/articles/360017912412-3-4-Cookie-Control-SDK-and-Inline-script
const consents = {
  cookie_cat_functional: 'functional',
  cookie_cat_statistic: 'statistic',
  cookie_cat_marketing: 'marketing',
};

const getCategory = (cat) => {
  if (!global.CookieInformation) {
    return false;
  }
  return global.CookieInformation.getConsentGivenFor(cat);
};

const getGiven = () =>
  Object.entries(consents).reduce(
    (output, [cat, name]) => ({
      ...output,
      [name]: getCategory(cat),
    }),
    {}
  );

const CookieProvider = ({ children }) => {
  const [given, setGiven] = useState(getGiven());
  if (global.addEventListener) {
    global.addEventListener('CookieInformationConsentGiven', () => {
      setGiven(getGiven());
    });
  }

  const contextProps = useMemo(() => ({ given }), [given]);

  return (
    <CookieContext.Provider
      value={contextProps}
    >
      {children}
    </CookieContext.Provider>
  );
};

CookieProvider.propTypes = {
  children: PropTypes.node,
};

CookieProvider.defaultProps = {
  children: undefined,
};

export { CookieProvider };

export default CookieContext;
