// Common Component : IfMobileHide

// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

// Styled Components
const IfMobileHide = styled.div.attrs({
  role: 'none',
})`
  display: none;
  ${media.tabletP`display:block;`};
`;

const IfDesktopHide = styled.div.attrs({
  role: 'none',
})`
  display: block;
  ${media.tabletP`display:none;`};
`;

// Exports
export { IfMobileHide, IfDesktopHide };
