// Common Component : AlignContentCenter

// Component used in : NavBarComponent.

// Import Node Modules
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const Align = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


// Class
class VAlignContentCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { className, children } = this.props;

    return <Align className={className}>{children}</Align>;
  }
}

// Proptypes
VAlignContentCenter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

VAlignContentCenter.defaultProps = {
  className: undefined,
  children: undefined,
};

// Exports
export default VAlignContentCenter;
