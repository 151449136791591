// import
import styled from 'styled-components';
import { currentTheme } from '../../../theme/index';
import SearchIcon from '../../../assets/Icons/svg/icon_Search_Blue.svg';
import { media } from '../../../cssGuides/MediaQuerys';
import * as Colors from '../../../cssGuides/colorGuide';
// Styled-components

export const SelectBox = styled.select`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.lightGray} !important;
  width: 100%;
  ${media.tabletP`
    width: 90%;
  `}
  ${media.tabletL`
    width: 90%;
  `}
  ${media.desktop`
    width: 300px;
  `}
`;


export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tabletL`
    flex-direction: row;
  `}
`;

export const Button = styled.div`
  display: inline-block;
  text-align:right;
  font-size: 14px;
  padding: 10px 0;
  margin-top:10px;
  border: 0;
  color: ${props => props.theme.PrimaryColor || currentTheme.PrimaryColor};
  cursor: pointer;
  order:2;
  ${media.tabletP`
    display: inline-block;
    width: 100%;
    text-align:right;
    font-size: 14px;
  `}
  ${media.tabletL`
    width: 150px;
    order:1;
    text-align:left;
  `}
`;

export const Search = styled.input`
  width: 100%  !important;
  display: inline-block  !important;
  background: url(${SearchIcon}) no-repeat scroll 280px 10px;
  border: 1px solid ${Colors.lightGray} !important;
  order:1;
  ${media.tabletP`
    width: 100%  !important;
    background: url(${SearchIcon}) no-repeat scroll 245px 10px;
  `}
  ${media.tabletL`
    width: 280px  !important;
    order:2;
  `}
  ${media.desktop`
    width: 300px  !important;
    background: url(${SearchIcon}) no-repeat scroll 265px 10px;

  `}
`;

export const Post = styled.div`
  border-top: 1px solid ${Colors.lightGray};
  padding: 10px 10px;
`;

export const Dato = styled.div`
  display: block;
  color: ${Colors.midGray};
  margin-right: 20px;
  ${media.tabletP`
    display: inline-block;
  `}
 `;

export const CatContainer = styled.div`
  margin: 0;
  padding: 0;
  display: inline-block;
`;

export const Cat = styled.div`
  border-right: 2px solid ${props => props.theme.PrimaryColor || currentTheme.PrimaryColor};
  color: ${props => props.theme.PrimaryColor || currentTheme.PrimaryColor};
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 14px;
  &:first-child {
    padding: 0;
    padding-right: 10px;
  }
  &:last-child {
    border: none;
  }
`;
