// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Common Components
import { Columns } from '../../../CommonComponents';

// Import Styled-components
import Hamburger from '../StyledComponents/Mobile';

// Class
const Mobile = (props) => {
  const {
    open,
    openNav,
    closeAll,
  } = props;

  const anyOpen = open.length > 0;
  return (
    <Columns columns={3} minWidth="300px">
      <div />
      <div style={{ textAlign: 'center' }}>
        {!anyOpen && (
          <Hamburger
            id="MenuOpen"
            onClick={() => {
              openNav('mySidenav');
            }}
          >
            &#9776;
          </Hamburger>
        )}
        {anyOpen && (
          <Hamburger
            id="MenuClose"
            onClick={() => {
              closeAll();
            }}
          >
            &times;
          </Hamburger>
        )}
      </div>
    </Columns>
  );
};

// Proptypes
Mobile.propTypes = {
  open: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  openNav: PropTypes.func.isRequired,
  closeAll: PropTypes.func.isRequired,
};

export default Mobile;
