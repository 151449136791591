// Common Component : Collapsable

// Import Node Modules
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Classes
const onNextFrame = fn => setTimeout(fn);

const getTransitionEventName = () => {
  if (!global.document || !global.document.createElement) {
    return undefined;
  }
  const el = global.document.createElement('fakeelement');

  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  const keys = Object.keys(transitions);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (el.style[key] !== undefined) {
      return transitions[key];
    }
  }
  return undefined;
};
const transitionEventName = getTransitionEventName();

class Collapsable extends React.Component {
  constructor(props) {
    super(props);
    const { collapsedHeight, collapsed } = props;
    this.setCollapsed = this.setCollapsed.bind(this);
    this.state = {
      height: collapsed ? collapsedHeight : undefined,
      overflow: 'hidden',
    };
  }

  componentDidUpdate(prev) {
    const { collapsed } = this.props;
    if (prev.collapsed !== collapsed) {
      this.setCollapsed(collapsed);
    }
  }

  getTransition() {
    const { transitionTime, timing } = this.props;
    return `height ${transitionTime}ms ${timing}`;
  }

  setCollapsed(collapsed) {
    const { collapsedHeight, transitionTime } = this.props;
    const innerNode = ReactDOM.findDOMNode(this.inner); // eslint-disable-line react/no-find-dom-node
    const outerNode = ReactDOM.findDOMNode(this.outer); // eslint-disable-line react/no-find-dom-node
    const innerHeight = innerNode.offsetHeight;
    const outerHeight = outerNode.offsetHeight;
    const timeoutTime = transitionTime + 100;
    if (collapsed) {
      this.setState({
        height: outerHeight,
        transition: undefined,
      });
      onNextFrame(() => {
        this.setState({
          height: collapsedHeight,
          overflow: 'hidden',
          transition: this.getTransition(),
        });
      });
    } else {
      this.setState({
        height: outerHeight,
        transition: undefined,
      });
      this.afterNextTransition(() => {
        this.setState({
          height: undefined,
          overflow: 'visible',
          transition: undefined,
        });
      }, timeoutTime);
      onNextFrame(() => {
        this.setState({
          height: innerHeight,
          transition: this.getTransition(),
        });
      });
    }
  }

  afterNextTransition(fn, timeout) {
    if (this.reset) {
      this.reset();
    }

    const outer = ReactDOM.findDOMNode(this.outer); // eslint-disable-line react/no-find-dom-node
    let timerId;

    const handler = () => {
      if (this.reset) {
        this.reset();
      }
      fn();
    };

    this.reset = () => {
      clearTimeout(timerId);
      if (transitionEventName) {
        outer.removeEventListener(transitionEventName, handler);
      }
    };

    if (transitionEventName) {
      outer.addEventListener(transitionEventName, handler);
    }
    if (timeout) {
      timerId = setTimeout(handler, timeout);
    }
  }

  render() {
    const { children } = this.props;
    const { height, transition, overflow } = this.state;
    return (
      <div
        style={{
          transition,
          height,
          overflow,
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={(r) => {
          this.outer = r;
        }}
        aria-hidden
      >
        <div
          ref={(r) => {
            this.inner = r;
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

// PropTypes
Collapsable.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  collapsedHeight: PropTypes.number,
  transitionTime: PropTypes.number,
  timing: PropTypes.string,
};

Collapsable.defaultProps = {
  collapsed: true,
  collapsedHeight: 0,
  transitionTime: 300,
  timing: 'ease-in-out',
};

// Exports
export default Collapsable;
