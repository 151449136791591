// Component Name : HistoryBack

// Component used in : HistoryBack.

// Import node Modules
import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

// Import Components
import styled from 'styled-components';

// Import Guides
import { media } from '../../../cssGuides/MediaQuerys';

// Images
import Arrow from '../../../assets/Icons/svg/icon_Arrow_Right_DarkGray.svg';

// Styled-components
const Styled = styled.div`
  position: absolute;
  top: 3px;
  left: 20px;
  cursor: pointer;
  font-size: 10px;
  ${media.tabletP`
    font-size:18px;
    top: 20px;
    left: 80px;
  `};
`;

const BackArrow = styled.img.attrs({
  alt: 'Arrow Left',
  src: Arrow,
})`
  position: relative;
  top: 2px;
  margin-right: 5px;
  height: 10px;
  transform: scaleX(-1);
  ${media.tabletP`
    height:auto;
    top: 2px;
    margin-right: 10px;
  `};
`;

// Class
const HistoryBack = (props) => {
  const { text, url } = props;

  return (
    <Styled onClick={() => navigate(url)}>
      <BackArrow />
      <div className="footnote" style={{ display: 'inline-block' }}>
        {text}
      </div>
    </Styled>
  );
};

// Proptypes
HistoryBack.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

// Exports
export default HistoryBack;
