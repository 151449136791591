// Import Node Modules
import styled, { css } from 'styled-components';

// Import Color Guide
import * as ColorGuide from '../../cssGuides/colorGuide';
// import { media } from '../../cssGuides/MediaQuerys';

// Import Components
import { Wrapper } from '../../components/CommonComponents/index';

// Styled Components

// export const BottomBorder = styled.div`
//   height: 20px;
//   width: 100%;
//   background-color: white;
//   position: fixed;
//   bottom: 0px;
//   left: 0px;
//   z-index: 2000;
//   display:none;
//   ${media.tabletP`
// display: block;
// `};
// ${media.tabletL`

// `};
// ${media.desktop`

// `};
// `;

const commonWrapperCSS = css`
  background-color: ${ColorGuide.lightGray};
`;

export const FrontPageWrapper = styled(Wrapper)`
  ${commonWrapperCSS};
`;

export const Template2Wrapper = styled(Wrapper)`
  ${commonWrapperCSS};
  ${props => (props.displayHeader ? '' : 'padding-top:20px;')};
  padding-bottom: 20px;
`;

export const Template3Wrapper = styled(Wrapper)`
  ${commonWrapperCSS};
  ${props => (props.displayHeader ? '' : 'padding-top:20px;')};
  padding-bottom: ${props => (props.bottomContent ? '' : '20px')};
`;

export const FullWidthWrapper = styled.div.attrs({
  role: 'none',
})`
  ${commonWrapperCSS};
  flex: 1;
`;
