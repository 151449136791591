// Import Node Modules
import React from 'react';

// Import Default Prop-Types
import DefaultPropTypes from './DefaultPropTypes';

// Import Components
import { CustomLayout } from './CustomLayout';
import { HistoryBack } from '../components/CommonComponents/index';

// Import Helper Functrion
import { GetDisplayHeader, RenderContent, isMobileOnClient } from './helperFunctions';

// Import Styled-Components
import { Template3Wrapper } from './components/Wrappers';
import { Template3Content } from './components';

// Template #3
class Template3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobileOnClient(),
    };
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleNavResize);
    this.handleNavResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  render() {
    const { children, data } = this.props;
    const displayHeader = GetDisplayHeader(data);
    const { mobile } = this.state;
    return (
      <CustomLayout data={data}>
        <Template3Wrapper
          displayHeader={displayHeader}
          minWidth="100%"
          bottomContent={
            data.bottomContent != null && data.bottomContent.length > 0
          }
        >
          {data.bannerImage ? RenderContent(data, mobile) : ''}
          <Template3Content displayHeader={displayHeader}>
            {data.backButton && (
              <HistoryBack text={data.backButtonText} url={data.backButtonUrl} />
            )}
            {children}
          </Template3Content>
          <div
            role="region"
            aria-label="Article Teasers"
            style={{ marginTop: '40px' }}
          >
            {data.bottomContent}
          </div>
        </Template3Wrapper>
      </CustomLayout>
    );
  }
}

Template3.propTypes = DefaultPropTypes;

export default Template3;
