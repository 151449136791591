// Common Component : InsertImage

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Styled-components
import { margins } from '../../../cssGuides/commonStyles';

// Styled Component
const StyledImg = styled.img`
  ${margins};
  width:100%;
`;

// Class
const InsertImage = (props) => {
  const { source, imageAltText } = props;
  return (
    <div>
      <StyledImg src={source} alt={imageAltText} />
    </div>
  );
};

// Proptypes
InsertImage.propTypes = {
  source: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
};

// Exports
export default InsertImage;
