// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';
import { margins } from '../../../cssGuides/commonStyles';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Styled-components
export const Container = styled.div`
  width: 100%;
  ${margins};
`;

export const Content = styled.div`
  border-radius: 7px;
  background-color: ${ColorGuide.lightGray};

  padding: 10px 10px 20px 10px;
  margin-bottom: -30px !important;

  ${media.tabletP`
    padding: 30px 30px 20px 30px;
    margin-bottom: -50px !important;
  `}
  ${media.tabletL`
    padding: 30px 30px 20px 30px;
    margin-bottom: -50px !important;
  `}
  ${media.desktop`
      padding: 30px 30px 20px 30px;
    margin-bottom: -60px !important;
  `}
`;

export const Block = styled.div`
  width: 100%;
  background-color: ${ColorGuide.lightGray};
`;

export const BlockContent = styled.div`
  height: 100%;

  padding: 10px 10px 20px 10px;
  margin-bottom: -20px !important;

  ${media.tabletP`
    padding: 30px 30px 20px 30px;
    margin-bottom: -30px !important;
  `}
  ${media.tabletL`
    padding: 30px 30px 20px 30px;
  `}
  ${media.desktop`
    padding: 30px 30px 0px 30px;
    margin-bottom: 30px !important;
  `}
`;

export const Header = styled.div`
  width: 100%;
  cursor: pointer;
  color: ${ColorGuide.secondaryText};
  background-color: ${ColorGuide.PUBLIC_READ_MORE_BACKGROUND};
  text-align: center;
  border-radius: 0 0 7px 7px;
  height: 60px;
  line-height: 60px;
`;

// Styled Icons
export const ArrowUp = styled.img`
  position: relative;
  top: -3px;
  transform: scaleY(-1);
`;

export const ArrowDown = styled.img`
  position: relative;
  top: -3px;
`;

export const Icon = styled.span`
  position: relative;
  top: 2px;
  padding-left: 10px;
  pointer-events: none;
`;
