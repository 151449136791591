import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Url } from 'components/CommonComponents';
import { SPACINGS } from 'cssGuides/commonStyles';
import * as ColorGuide from 'cssGuides/colorGuide';
import { media } from 'cssGuides/MediaQuerys';
import RightArrow from 'assets/Icons/svg/icon_Arrow_Right_Blue.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.tabletP`
    flex-direction: row;
  `}
`;

const Image = styled.div`
  ${({ source }) => css`
    background-image: url(${source});
    background-position: center;
    background-size: cover;
  `};
  width: 100%;
  height: 350px;

  ${media.tabletP`
    width: 50%;
    height: 100%;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACINGS.md}px ${SPACINGS.md}px 0 ${SPACINGS.md}px;

  a,
  h2,
  p {
    color: ${ColorGuide.darkGray};
  }

  ${media.tabletP`
    width: 50%;
  `}

  ${media.tabletL`
    padding: ${SPACINGS.xl}px;
  `}
`;

const Type = styled.p`
  text-transform: uppercase;
  margin-bottom: ${SPACINGS.s}px;
`;

const Title = styled.h2``;

const ReadMoreWrapper = styled.div`
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  ${media.tabletP`
    left: 50%;
    width: 50%;
  `}
`;

const Arrow = styled.img`
  position: relative;
  top: ${SPACINGS.xs}px;
  left: ${SPACINGS.xs}px;
`;

const ReadMore = styled.p`
  display: block;
  width: 100%;
  text-align: right;
  background-color: white;
  font-weight: 500;
  margin-bottom: 0;
  padding: ${SPACINGS.m}px ${SPACINGS.lg}px ${SPACINGS.m}px 0;
  color: ${ColorGuide.darkGray};

  ${media.tabletP`
    padding: ${SPACINGS.lg}px ${SPACINGS.lg}px ${SPACINGS.lg}px 0;
  `}
`;

const Manchet = styled.p``;

const SlideContent = ({
  type, header, manchet, source, url,
}) => {
  return (
    <Url url={url}>
      <Wrapper>
        <Image source={source} alt={header} />
        <TextWrapper>
          <Type>{type}</Type>
          <Title>{header}</Title>
          <Manchet>{manchet}</Manchet>
        </TextWrapper>
        {url && (
          <ReadMoreWrapper>
            <ReadMore>
              Læs mere
              <Arrow src={RightArrow} alt="Arrow Right" />
            </ReadMore>
          </ReadMoreWrapper>
        )}
      </Wrapper>
    </Url>
  );
};

SlideContent.propTypes = {
  header: PropTypes.string.isRequired,
  manchet: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  url: PropTypes.string,
};

SlideContent.defaultProps = {
  url: '',
};

export default SlideContent;
