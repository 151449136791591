// Component : Login Button

// Import Node Modules
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Import Components
import { media } from '../../../../cssGuides/MediaQuerys';
import { AlignContentCenter } from '../../../CommonComponents/index';

import * as ColorGuide from '../../../../cssGuides/colorGuide';

// Styled-Components
const commonLinkStyle = css`
  background-color: ${props => props.theme.PrimaryColor};
  color: ${ColorGuide.white} !important;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  justify-self: end;
  justify-content: flex-end;
  width: 70px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  margin-left: 30px;

  ${media.tabletP`
    margin: 0 auto;
    width: 80px;
    height: 30px;
    line-height: 30px;
  `};
  ${media.desktop`
    width:105px;
    height:40px;
    line-height:40px;
  `};
`;

const LoginContainer = styled.div`
  height: ${props => props.NH}px;
`;

const StyledLink = styled(Link)`
  ${commonLinkStyle}
`;

export const LoginBtnComponent = props => (
  // eslint-disable-next-line react/destructuring-assignment
  <LoginContainer NH={props.navheight}>
    <AlignContentCenter style={{ textAlign: 'right' }}>
      <StyledLink {...props} />
    </AlignContentCenter>
  </LoginContainer>
);

// Proptypes
LoginBtnComponent.propTypes = {
  navheight: PropTypes.number,
};

LoginBtnComponent.defaultProps = {
  navheight: undefined,
};

export default LoginBtnComponent;
