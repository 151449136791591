// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import Default Image
import DefaultImage from '../../../assets/DefaultImages/FrontPageBanner.png';
import DefaultMobileImage from '../../../assets/DefaultImages/FrontPageBannerMobile.png';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Import Common Styles
import { margins } from '../../../cssGuides/commonStyles';

// Styled-Components
const Overlay = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
`;

const OverlayMobile = styled.div`
  position: relative;
  display: block;
  background-color: ${ColorGuide.white};
  padding-top: 15px;
  width: 95%;
  margin: 20px auto;
  border-radius: 4px;
  box-shadow: ${ColorGuide.shadow};
  padding: 10px;
  h1 {
    color: black !important;
  }
  p {
    color: black !important;
  }
`;

// Class
const BannerOverlayText = (props) => {
  const {
    image,
    children,
    className,
    fullSize,
    displayMobile,
    noText,
    altText,
    mobile,
  } = props;

  const fullWidth = css`
    margin: -20px -10px 0px -10px;
    width: calc(100% + 40px);

    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    ${media.tabletP`
      margin: -50px -25px 0px -25px;
      width: calc(100% + 100px);
    `};

    ${media.tabletL`
      margin: -60px -30px 0px -30px;
      width: calc(100% + 120px);
    `};

    ${media.desktop`
      margin: -80px -40px 20px -40px;
      width: calc(100% + 160px);
    `};
  `;

  const Container = styled.div`
    width: 100%;
    ${fullSize != null && margins};
  `;

  const CommonCSS = css`
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    ${fullSize != null && fullWidth};
  `;

  const Inner = styled.div`
    ${CommonCSS};
  `;

  const Image = styled.img`
    ${CommonCSS};
  `;

  return (
    <div>
      <Container className={className}>
        <Inner role="none">
          <div>
            <Image
              src={image}
              alt={altText}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = mobile === true ? DefaultMobileImage : DefaultImage;
                e.target.alt = 'Default Image';
              }}
            />
            {displayMobile && <Overlay role="none">{children}</Overlay>}
            {!mobile && <Overlay role="none">{children}</Overlay>}
          </div>
        </Inner>
      </Container>
      {!displayMobile && mobile === true && noText && (
        <OverlayMobile>{children}</OverlayMobile>
      )}
    </div>
  );
};

// Proptypes
BannerOverlayText.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullSize: PropTypes.bool,
  displayMobile: PropTypes.bool,
  noText: PropTypes.bool,
  altText: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
};

BannerOverlayText.defaultProps = {
  className: undefined,
  fullSize: undefined,
  displayMobile: undefined,
  noText: undefined,
  altText: 'Image No Description',
};

// Exports
export default BannerOverlayText;
