// Common Components : Promote_app

// Import Node Modules
import React from 'react';
import styled from 'styled-components';

// Import Images
import AppStore from '../../../assets/Images/AppStore.png';
import GooglePlay from '../../../assets/Images/GooglePlay.png';

// Styled Components
export const ImgContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export const DownloadAt = styled.img`
  height: 40px;
  margin: 8px;
`;

// Class
const PromoteApp = () => (
  <ImgContainer role="group" aria-label="Links to App">
    <a href="https://itunes.apple.com" target="_blank" rel="noopener noreferrer" aria-label="App Store">
      <DownloadAt src={AppStore} alt="Download @ AppStore" />
    </a>
    <a href="https://play.google.com" target="_blank" rel="noopener noreferrer" aria-label="Google Play">
      <DownloadAt src={GooglePlay} alt="Download @ GooglePlay" />
    </a>
  </ImgContainer>
);

// Exports
export default PromoteApp;
