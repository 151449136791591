// Import Node Modules
import styled from 'styled-components';

// Media Queries
import { media } from '../../../../cssGuides/MediaQuerys';

// Import Guides
import * as ColorGuide from '../../../../cssGuides/colorGuide';

// Styled components - RenderDesktop
export const MegaMenuHeaderContainer = styled.div`
  margin: auto auto;
  text-align: center;
  transition: all 0.3s;
  height: 100%;
`;

export const MegaMenuHeader = styled.div`
  display: inline;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  white-space: nowrap;
  color: ${ColorGuide.white};
  font-weight: ${({ item, isActive }) => (item === isActive ? 500 : 400)};
  &:hover {
    color: ${ColorGuide.white};
  }
  ${media.tabletP`font-size: 11px;`};
  ${media.tabletL`font-size: 14px;`};
  ${media.desktop`font-size: 16px;`};
`;

export const MegaMenuItemUnderline = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${({
    id,
    item,
    isActive,
    isLoaded,
  }) => (item === isActive || id === isLoaded
    ? ColorGuide.white
    : ColorGuide.darkGray)};
`;
