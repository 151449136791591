// Import Node Modules
import React from 'react';

// Import Default Prop-Types
import DefaultPropTypes from './DefaultPropTypes';

// Import Components
import { CustomLayout } from './CustomLayout';

// Import Styled-Components
import { FullWidthWrapper } from './components/Wrappers';

// Template Full Width
const FullWidth = ({ children, data }) => (
  <CustomLayout data={data}>
    <FullWidthWrapper>{children}</FullWidthWrapper>
  </CustomLayout>
);

// Prop-Types
FullWidth.propTypes = DefaultPropTypes;

export default FullWidth;
