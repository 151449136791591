// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

// Import icon
import SearchIcon from '../../../../assets/Icons/svg/icon_Search_Blue.svg';

// Styled-Components

const Container = styled.div`
  background-color: white;
  border-radius: 7px;
  width: 90%;
  margin: 10px auto;
`;

const Icon = styled.img.attrs({
  alt: 'Search Icon',
})`
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
  left: 10px;
`;

const StyledInput = styled.input`
  width: calc(100% - 30px) !important;
  margin-top: 0px !important;
  &:focus {
    border: 1px solid white !important;
  }
`;

// Class
class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    const { value } = this.state;
    const { path } = this.props;
    navigate(`${path}#searchUrl=${value}`);
    event.preventDefault();
    global.document.body.style.overflow = 'auto';
  }

  render() {
    const { value } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <Container>
          {/* Disabled because styled input is not recognized */}
          {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
          <label htmlFor="search">
            <Icon src={SearchIcon} />
            <StyledInput
              type="text"
              value={value}
              onChange={this.handleChange}
              placeholder="Skriv søgeord her"
              id="search"
            />
          </label>
        </Container>
      </form>
    );
  }
}

// Proptypes
SearchBar.propTypes = {
  path: PropTypes.string.isRequired,
};

export default SearchBar;
