// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled-components
import {
  MegaMenuHeaderContainer,
  MegaMenuHeader,
  MegaMenuItemUnderline,
} from '../StyledComponents/Desktop';

// Component Class Desktop
const Desktop = (props) => {
  const {
    ActiveMenu,
    ActiveMegaMenu,
    isLoaded,
    menu,
    changeMegaMenu,
  } = props;

  return (
    <MegaMenuHeaderContainer>
      {menu.menus.subMenus.map((subMenus) => {
        return ActiveMenu === subMenus.id ? (
          <div key={subMenus.id} role="menu" aria-label="Mega Menu Bar">
            {subMenus.menuItems.map(menuItems => (
              <MegaMenuHeader
                role="button"
                key={menuItems.id}
                onClick={() => changeMegaMenu(menuItems.name)}
                item={menuItems.name}
                isActive={ActiveMegaMenu}
              >
                {menuItems.name}
                <MegaMenuItemUnderline
                  id={menuItems.id}
                  item={menuItems.name}
                  isActive={ActiveMegaMenu}
                  isLoaded={isLoaded}
                />
              </MegaMenuHeader>
            ))}
          </div>
        ) : (
          ''
        );
      })}
    </MegaMenuHeaderContainer>
  );
};

Desktop.propTypes = {
  ActiveMenu: PropTypes.string.isRequired,
  ActiveMegaMenu: PropTypes.string.isRequired,
  isLoaded: PropTypes.string,
  menu: PropTypes.shape({
    searchUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    menus: PropTypes.shape({
      subMenus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        display: PropTypes.string,
        internal: PropTypes.bool,
        menuItems: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })),
      })).isRequired,
    }).isRequired,
  }).isRequired,
  changeMegaMenu: PropTypes.func.isRequired,
};

Desktop.defaultProps = {
  isLoaded: undefined,
};

export default Desktop;
