// Common Component : Wrapper

// Component used in : MainMenu, MegaMenu, CookieBanner.

// Import Node Modules
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Variables
const width = props => (props.minWidth ? props.minWidth : '1024px');

// Class
const Wrapper = styled.div`
  @media (min-width: ${width}) {
    width: ${width};
    margin: 0 auto;
  }
  @media (max-width: ${width}) {
    width: 100%;
  }
  flex: 1;
`;

// Proptypes
Wrapper.propTypes = {
  minWidth: PropTypes.string,
};

// Exports
export default Wrapper;
