// Import ColorGuide
// import * as ColorGuide from '../cssGuides/colorGuide';

// Import Logos
import LogoMobile from '../assets/Logo/white.svg';
import LogoDesktop from '../assets/Logo/sampension_mere_vaerd_neg.svg';
import SquareLogo from '../assets/OGData/Sampension1200.jpg';

export default {
  // Firma Domaine
  Domaine: 'FirmaPension.dk',
  // Primary Web Color
  PrimaryColor: '#54a8d1',
  PrimaryHover: '#4291b8',
  PrimaryVisited: '#4f4586',
  // Logos
  DesktopLogo: LogoDesktop,
  MobileLogo: LogoMobile,
  SquareLogo,
  // Logo Sizes
  LogoSizeMobile: '14px',
  LogoSizeTabletP: '38px',
  LogoSizeTabletL: '40px',
  LogoSizeDesktop: '50px',
  // Logo left padding
  LogoLeftMobile: '25px',
  LogoLeftTabletP: '25px',
  LogoLeftTabletL: '25px',
  LogoLeftDesktop: '25px',
  // Logo container size
  LogoContainerSize: '256px',
  // MainMenu ON/OFF
  MainMenuSeperator: true,
  // Footer Links
  FooterLinks: true,
  // Button Name (Login)
  BtnName: 'Log ind',
  // Root folder
  StartUrl: '/Firmapension',
  // Mobile Table  Collapse
  MobileTable: '#bbdced',
  // Search enigne key
  EngineKey: 'XRhnzB8G5igdtwYjki1Z',
};
