/* eslint-disable react/no-multi-comp */
// Common Components : UniCodeIcons

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import ColorGuide
import * as ColorGuide from '../../../cssGuides/colorGuide';

// UniCodeIcons
const Icon = styled.span`
  color: ${props => (props.color ? props.color : ColorGuide.aqua)};
`;

export const IconUp = (props) => {
  const { color } = props;
  return <Icon color={color}>&and;</Icon>;
};

export const IconDown = (props) => {
  const { color } = props;
  return <Icon color={color}>&or;</Icon>;
};

export const IconLeft = (props) => {
  const { color } = props;
  return <Icon color={color}>&#x276E;</Icon>;
};

export const IconRight = (props) => {
  const { color } = props;
  return <Icon color={color}>&#x276F;</Icon>;
};

export const ArrowRight = (props) => {
  const { color } = props;
  return <Icon color={color}>&#x2794;</Icon>;
};

export const Dash = (props) => {
  const { color } = props;
  return <Icon color={color}>&#x2501;</Icon>;
};

export const SpecialArrow = () => <span>&#x21E8;</span>;

// Proptypes

const commonPropTypes = {
  color: PropTypes.string,
};

const defaultPropTypes = {
  color: ColorGuide.aqua,
};

IconUp.propTypes = commonPropTypes;
IconDown.propTypes = commonPropTypes;
IconLeft.propTypes = commonPropTypes;
IconRight.propTypes = commonPropTypes;
ArrowRight.propTypes = commonPropTypes;
Dash.propTypes = commonPropTypes;

IconUp.defaultProps = defaultPropTypes;
IconDown.defaultProps = defaultPropTypes;
IconLeft.defaultProps = defaultPropTypes;
IconRight.defaultProps = defaultPropTypes;
ArrowRight.defaultProps = defaultPropTypes;
Dash.defaultProps = defaultPropTypes;
