// Component Name : Slideshow
// C1 CMS Name : Carousel, CarouselNews

// Import Node Modules
import React from 'react';
import makeCarousel from 'react-reveal/makeCarousel';
import { withTheme } from 'styled-components';
import Slide from 'react-reveal/Slide';
import PropTypes from 'prop-types';

import { Dash } from 'components/CommonComponents/UniCodeIcons';
import sliderArrow from 'assets/Icons/svg/icon_Arrow_SlideSHow.svg';
import * as ColorGuide from 'cssGuides/colorGuide';

import {
  Dashes,
  Container,
  Header,
  Manchet,
  CarouselUIContainer,
  Children,
  ArrowLeft,
  ArrowRight,
  Dots,
  Dot,
  SlideContainer,
  SlideContent,
} from './styledcomponents';

// Class
const CarouselUI = withTheme(({
  position,
  total,
  handleClick,
  children,
  theme,
}) => (
  <CarouselUIContainer role="group" aria-label="Image slideshow">
    <Children aria-hidden="true">
      {children}
      <ArrowLeft
        src={sliderArrow}
        onClick={handleClick}
        data-position={position - 1}
        alt="Arrow Left"
      />
      <ArrowRight
        src={sliderArrow}
        onClick={handleClick}
        data-position={position + 1}
        alt="Arrow Right"
      />
    </Children>
    <Dots aria-hidden="true">
      {Array(...Array(total)).map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Dot key={index} onClick={handleClick} data-position={index}>
          {index === position ? (
            <Dashes>
              <Dash color={theme.PrimaryColor} />
            </Dashes>
          ) : (
            <Dashes>
              <Dash color={ColorGuide.lightGray} />
            </Dashes>
          )}
        </Dot>
      ))}
    </Dots>
  </CarouselUIContainer>
));

const Carousel = makeCarousel(CarouselUI);

const SlideShow = (props) => {
  const { header, manchet, children } = props;

  return (
    <Container aria-label="Image Slideshow">
      <Header role="heading">{header}</Header>
      <Manchet className="intro">{manchet}</Manchet>
      <Carousel defaultWait={10000} swipe>
        {React.Children.map(children, child => (
          <Slide right key={child.props.id}>
            <SlideContainer>
              <SlideContent>{child}</SlideContent>
            </SlideContainer>
          </Slide>
        ))}
      </Carousel>
    </Container>
  );
};

// Proptypes
CarouselUI.propTypes = {
  position: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

SlideShow.propTypes = {
  header: PropTypes.string.isRequired,
  manchet: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SlideShow;
