// Component Name : CookieInformation
// C1 CMS Name : CookieInformation

// Import Node Modules
import React, { Component } from 'react';

// Class
class CookieInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const script = global.document.createElement('script');

    // script.src = 'https://policy.app.cookieinformation.com/421687/sampension.dk/declaration-default.js'
    // script.src = 'http://krogolsen.dk/script.js'
    // script.src = require('./script.js')
    script.src = 'https://policy.cookieinformation.com/71efcd23-288b-4d47-be21-1dc20761f867/cd.js';
    script.async = true;
    script.id = 'CookiePolicy';

    global.document.getElementById('cookie-output').appendChild(script);
  }

  render() {
    return <div id="cookie-output" role="region" aria-label="Cookie Information" />;
  }
}

// Exports
export default CookieInformation;
