// Component Name : IconCard
// C1 CMS Name : LinkBox m/Icon

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import TextIcon from './CardContent/icon';

// Component
const IconCard = (props) => {
  const {
    icon,
    header,
    text,
    url,
    iconAltText,
  } = props;
  return (
    <Card shadow maxWidth="100%" minHeight="100%" url={url}>
      <TextIcon arrow icon={icon} altText={iconAltText} header={header} text={text} />
    </Card>
  );
};

// Proptypes
IconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAltText: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

IconCard.defaultProps = {
  iconAltText: 'No Icon Description',
};

export default IconCard;
