// Component Name : WideCard
// C1 CMS Name : KampagneLinkBox

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import Wide from './CardContent/wide';

// Component
const WideCard = (props) => {
  const {
    imageRight,
    image,
    imageMobile,
    text,
    btnUrl,
    imageAltText,
  } = props;
  return (
    <Card shadow maxWidth="1060px" minHeight="100%" url={btnUrl}>
      <Wide imageRight={imageRight} image={image} imageMobile={imageMobile} altText={imageAltText} text={text} />
    </Card>
  );
};

// Proptypes
WideCard.propTypes = {
  imageRight: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnUrl: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
  imageMobile: PropTypes.string,
};

WideCard.defaultProps = {
  imageAltText: 'Image No Description',
  imageMobile: undefined,
};

export default WideCard;
