import { useContext } from 'react';
import PuzzelContext from './PuzzelContext';

const ChatView = ({ children }) => {
  const {
    open, onClickOpen, visible,
  } = useContext(PuzzelContext);

  return children({
    status: open ? 'open' : 'closed',
    visible,
    onClickOpen,
  }) || null;
};

export default ChatView;
