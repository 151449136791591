// Import Node Modules
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Color Variables
import * as ColorGuide from '../../../../cssGuides/colorGuide';
import { media } from '../../../../cssGuides/MediaQuerys';

// Import Default Profile Image
import DefaultImage from '../../../../assets/DefaultImages/BusinessCard.png';

// Styled Component
const common = css`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  padding: 0 30px;
  ${media.tabletP`
    font-size: 10px;
  `};
  ${media.tabletL`
    font-size: 10px;
  `};
  ${media.desktop`
    font-size: 14px;
  `};
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 1;
  ${props => (props.image === '' ? 'padding: 20px 0;' : 'padding-bottom: 20px;')};
`;

export const Name = styled.div`
  ${common};
  font-weight: bold;
`;

export const Job = styled.div`
  ${common};
  margin-bottom: 10px;
`;

export const TLF = styled.div`
  ${common};
`;

export const Email = styled.div`
  ${common};
  a {
    text-decoration: none;
    color: ${ColorGuide.aquaBright};
  }
`;

const Image = styled.img`
  margin-bottom: 10px;
  display: none;
  ${media.tabletP`
    display: block;
  `};
`;

// Component
const BusinessCard = (props) => {
  const {
    name,
    job,
    tlf,
    email,
    altText,
    image,
  } = props;
  const mailto = `mailto:${email}`;
  return (
    <Container image={image}>
      {image === '' ? null : (
        <Image
          src={image}
          alt={altText}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DefaultImage;
            e.target.alt = 'Default Image';
          }}
        />
      )}
      <Name role="none">{name}</Name>
      {job !== '' && <Job role="none">{job}</Job>}
      {tlf !== '' && <TLF role="none">Telefon: {tlf}</TLF>}
      {email !== '' && (
        <Email role="none">
          <a href={mailto}>{email}</a>
        </Email>
      )}
    </Container>
  );
};

BusinessCard.propTypes = {
  /** Image source */
  image: PropTypes.string,
  /** alternate image text */
  altText: PropTypes.string,
  /** Profile Name */
  name: PropTypes.string.isRequired,
  /** Profile job title */
  job: PropTypes.string.isRequired,
  /** Profile phone number */
  tlf: PropTypes.string.isRequired,
  /** Profile email */
  email: PropTypes.string.isRequired,
};

BusinessCard.defaultProps = {
  image: '',
  altText: 'Default profile image',
};

export default BusinessCard;
