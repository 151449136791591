// Import Node Modules
import styled from 'styled-components';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Styled-components
export const LevelOneHeader = styled.div`
  width: 100%;
  background-color: ${props => props.theme.PrimaryColor};
  text-align: left;
  font-size: 18px;
  padding: 16px 20px;
  color: ${ColorGuide.white};
  font-weight: 700;
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${ColorGuide.lightGray};
  text-align: left;
  font-size: 18px;
  padding: 16px 20px;
  color: ${ColorGuide.black};
  font-weight: 400;
  &:nth-child(odd) {
    background-color: ${ColorGuide.border};
  }
`;

export const Header = styled.div`
  width: 100%;
  cursor: pointer;
  background-color: ${ColorGuide.aquaMedium};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  padding:10px;
`;
