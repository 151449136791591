// Import Node Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';


// Import Components
import {
  Container,
  Header,
  Manchet,
  InputBox,
  InputBtn,
} from './styledcomponents';

// Class
class SignupEmail extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    // alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    const {
      header,
      manchet,
      placeholder,
      btnText,
    } = this.props;

    const { value } = this.state;

    return (
      <Container aria-label="Sign up Email">
        <Header role="heading" tabIndex="0">{header}</Header>
        <form onSubmit={this.handleSubmit}>
          {/* Disabled because styled input is not recognized */}
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="email"><Manchet className="intro" tabIndex="0">{manchet}</Manchet>
            <InputBox
              id="email"
              type="email"
              value={value}
              onChange={this.handleChange}
              placeholder={placeholder}
              required
            />
          </label>
          <InputBtn type="submit" value={btnText} />
          <br />
        </form>
      </Container>
    );
  }
}

// Proptypes
SignupEmail.propTypes = {
  header: PropTypes.string.isRequired,
  manchet: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
};

// Exports
export default SignupEmail;
