// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../cssGuides/MediaQuerys';

// Import Color Guide
import * as ColorGuide from '../../cssGuides/colorGuide';

// Components

const BannerContent = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 95%;

  p,
  h1 {
    color: ${ColorGuide.white};
    text-shadow: 1px 1px 2px black;
  }

  ${media.tabletP`
    width:70%;
  `};
  ${media.tabletL`
    width:90%;
  `};
  ${media.desktop`
    width:1060px;
    margin-bottom: ${props => props.bottomMargin};
  `};
`;

// Exports
export default BannerContent;
