// Import Themes
import sampensionTheme from './sampension';
import ispTheme from './isp';
import firmaPensionTheme from './firmapension';
import apTheme from './ap';
import pjdTheme from './pjd';

const themes = {
  sampension: sampensionTheme,
  isp: ispTheme,
  firma: firmaPensionTheme,
  ap: apTheme,
  pjd: pjdTheme,
};

const themeName = process.env.GATSBY_THEME || 'sampension';

export const currentTheme = themes[themeName];

export default themes;
