import * as components from './PageComponents';
import * as common from './CommonComponents';
import * as templates from '../template';

const componentsNormalized = Object.keys(components).filter(key => key !== '__esModule').reduce((output, key) => ({
  ...output,
  [key]: components[key],
}), {});

const templatesNormalized = Object.keys(templates).filter(key => key !== '__esModule').reduce((output, key) => ({
  ...output,
  [key]: templates[key],
}), {});

const commonsNormalized = Object.keys(common).filter(key => key !== '__esModule').reduce((output, key) => ({
  ...output,
  [key]: common[key],
}), {});

export default {
  ...componentsNormalized,
  ...templatesNormalized,
  ...commonsNormalized,
};
