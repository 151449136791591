import React, {
  useMemo,
  useState,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import PuzzelClient from './PuzzelClient';

const GlobalStyle = createGlobalStyle`
  div[data-puzzel-chat] {
    z-index: 1000;
  }
`;

const PuzzelContext = createContext({
});

// HACK: as Puzzel sets variables in localStorage, including CPR
// which is not secure enough, we have to catch this set and remove the CPR
if (global.window) {
  const orgSetItem = global.localStorage.setItem;
  global.localStorage.setItem = (key, value) => {
    let result = value;
    if (key === '[euwa_chat]startFormVariables') {
      const json = JSON.parse(value);
      json.CPR = undefined;
      result = JSON.stringify(json);
    }
    orgSetItem.apply(global.localStorage, [key, result]);
  };
}

const PuzzelProvider = ({ children, id }) => {
  const client = useMemo(() => new PuzzelClient(id), []);
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!global.window) {
      return;
    }

    client.init({
      // Eventlist: https://help.puzzel.com/da/product-documents/user-guide/puzzel-contact-centre/puzzel-administration-portal/services/chat-configuration/chat-front-end-api
      chatInit: () => {
        setOpen(true);
      },
      chatStarts: () => {
        setVisible(true);
      },
      chatWindowClosed: () => {
        setVisible(false);
      },
      contactCenterClosed: () => {
        setOpen(false);
      },
    }).then((isReady) => {
      setReady(isReady);
    });
  }, [client]);

  const onClickOpen = useCallback(() => {
    setVisible(true);
    client.startChat();
  }, [client]);

  const contextProps = useMemo(() => ({
    ready,
    client,
    open: open && ready,
    onClickOpen,
    visible,
  }), [open, ready, visible, client, onClickOpen]);


  return (
    <PuzzelContext.Provider
      value={contextProps}
    >
      <GlobalStyle />
      {children}
    </PuzzelContext.Provider>
  );
};

PuzzelProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export { PuzzelProvider };

export default PuzzelContext;
