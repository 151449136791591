// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS A-href

const globalLink = css`
  a {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    font-weight: 500;
    text-decoration: none;
    color: ${ColorGuide.aquaBright};

    &:visited {
      color: ${props => props.theme.PrimaryVisited};
    }

    &:hover {
      color: ${props => props.theme.PrimaryHover};
    }

    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }
  }
`;

export default globalLink;
