// Component Name : Columns

// Component is used in : Footer, MegaMenu, MobileMenu, SideBar, Beta, WideCard, HtmlTable, SLideshow

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../../cssGuides/MediaQuerys';

// Styled Components
const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${props => (props.disableWrap ? 'no-wrap' : 'wrap')};
  justify-content: center;
  ${media.tabletP`
    justify-content: space-between;
  `};
  ${props => (props.flex ? `flex: ${props.flex};` : '')}
  @media (min-width: ${props => props.minWidth}) {
    & > div {
      flex-basis: calc((100% - (${props => props.columns - 1}) * ${props => props.gap}px) / ${props => props.columns});
      flex-wrap: nowrap;
    }
  }
  @media (max-width: ${props => props.minWidth}) {
    & > div {
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }
`;

// Component
const Columns = (props) => {
  const {
    gap,
    minWidth,
    className,
    children,
    columns,
    disableWrap,
    flex,
  } = props;
  return (
    <Container
      flex={flex}
      columns={columns}
      minWidth={minWidth}
      gap={gap}
      className={className}
      disableWrap={disableWrap}
    >
      {children}
    </Container>
  );
};

// Proptypes
Columns.propTypes = {
  columns: PropTypes.number.isRequired,
  minWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: PropTypes.string,
  className: PropTypes.string,
  disableWrap: PropTypes.bool,
  flex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Columns.defaultProps = {
  minWidth: '762px',
  gap: '0',
  className: undefined,
  flex: false,
  disableWrap: false,
};

// Exports
export default Columns;
