// Import Node Modules
import styled, { css } from 'styled-components';

// Import Icons
import SearchIcon from '../../../assets/Icons/svg/icon_Search_Blue.svg';

// Import Guides
import { media } from '../../../cssGuides/MediaQuerys';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Variables

const BorderRadius = '7px';

const sizes = css`
  width: 95%;
  ${media.tabletP`
    width: 640px;
  `};
  ${media.tabletL`
    width: 840px;
  `};
  ${media.desktop`
    width: 840px;
  `};
`;

// Styled-Components - SearchBar

export const OuterContainer = styled.div`
  position: relative;
`;

export const Container = styled.div`
  margin: 20px auto 0 auto;
  ${sizes};

  input {
    ${sizes};
    font-size: 20px;
    height: 60px;
    padding-left: 15px;
    border: none;
    border: 1px solid ${ColorGuide.white};
    border-radius: ${BorderRadius};
    background-size: 25px;
    background-position: right 15px center;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;

    &:focus {
      outline: none;
      border: 1px solid ${ColorGuide.aqua};
      border-radius: ${BorderRadius};
      outline-offset: 0;
    }
    &::placeholder {
      opacity: 0.3;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: ${ColorGuide.darkGray};
    }
  }
`;

// Spinner

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 500px);
`;

// Content

export const ContentContainer = styled.div`
  margin: 20px auto;
  ${sizes};
  background-color: white;
  padding: 20px;
  border-radius: 7px;
  box-shadow: ${ColorGuide.shadow};
`;

// Shows count

export const ShowCount = styled.h2`
  margin-top: 20px;
  text-align: center;
`;

// ResultItem

export const ResultItem = styled.div`
  position: relative;
  border-bottom: 1px solid ${ColorGuide.darkGray};
  padding: 5px 50px 5px 10px;
  &:last-child {
    border-bottom: none;
  }
  em {
    border-bottom: 1px dashed ${ColorGuide.yellow};
    font-style: normal;
  }
  a {
    color: ${ColorGuide.darkGray};
  }
`;

export const Arrow = styled.img.attrs({
  alt: 'Arrow Right',
})`
  position: absolute;
  top: calc(50% - (15px / 2));
  right: 10px;
`;

// Suggestions

export const SuggestionsContainer = styled.div`
  margin: 0 auto;
  ${sizes};
  background-color: white;
  box-shadow: ${ColorGuide.shadow};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 122px;
  padding: 10px;
  border-radius: 7px;
  z-index: 1;
`;

export const SuggestionItem = styled.div`
  border-bottom: 1px solid ${ColorGuide.darkGray};
  padding: 5px;
  em {
    border-bottom: 1px dashed ${ColorGuide.yellow};
    font-style: normal;
  }
`;

// Pagination

export const PageContainer = styled.div`
  margin: 0 auto;
  ${sizes};
  text-align: center;
`;

export const PageButton = styled.div`
  border: 1px solid ${ColorGuide.aqua};
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: ${props => (props.show ? 'white' : '')};
  cursor: pointer;
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
`;
