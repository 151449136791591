// Import Node Modules
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Import Components
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Styled Components
const Container = styled.div`
  width: 100%;
  /* background-color: ${ColorGuide.aquaMedium}; */
  background-color: ${props => props.theme.MobileTable};
  text-align: left;
  font-size: 18px;
  color: ${ColorGuide.black};
  font-weight: 400;
`;

const Header = styled.div`
  width: 100%;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 10px;
`;

const Content = styled.div`
  overflow: hidden;
  transition: height 0.3s ease-out;
  text-align: center;
`;

const Icon = styled.span`
  position: relative;
  top: -2px;
  font-size: 15x;
  padding-left: 10px;
  float: right;
`;

const TitleWrapper = styled.div`
  p {
    margin: 0;
    display: inline;
  }
`;

// Class
class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle(e) {
    e.preventDefault();
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
      // eslint-disable-next-line react/no-find-dom-node
      height: ReactDOM.findDOMNode(this.inner).clientHeight,
    });
  }

  render() {
    const { title, children } = this.props;
    const { isExpanded, height } = this.state;
    const currentHeight = isExpanded ? height : 0;

    const ContainerStyle = `${isExpanded ? 'is-expanded' : ''}`;

    return (
      <Container
        className={ContainerStyle}
        role="group"
        aria-label="Collapsible"
      >
        <Header role="heading" onClick={e => this.handleToggle(e)}>
          <Icon>{isExpanded ? '−' : '+'}</Icon>
          <TitleWrapper dangerouslySetInnerHTML={{ __html: title }} />
          {/* {title}  */}
        </Header>
        <Content
          style={{ height: `${currentHeight}px` }}
          aria-label="Links"
          role="group"
        >
          <div
            ref={(r) => {
              this.inner = r;
            }}
          >
            {children}
          </div>
        </Content>
      </Container>
    );
  }
}

// Proptypes
Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Exports
export default Collapsible;
