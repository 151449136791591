// Component Name : CollapsibleTextArea
// C1 CMS Name : FoldUdBox

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Collapsable from '../../CommonComponents/Collapsible/Collapsable';
import {
  Container,
  Content,
  Block,
  BlockContent,
  Header,
  ArrowUp,
  ArrowDown,
  Icon,
} from './styles';

// Import Icons
import Arrow from '../../../assets/Icons/svg/icon_Arrow_Down_Blue.svg';

// Class
class CollapsibleTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle(e) {
    e.preventDefault();
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  render() {
    const {
      openText,
      closeText,
      children,
      text,
    } = this.props;

    const { isExpanded } = this.state;
    return (
      <Container>
        <Content>{text}</Content>
        <Block>
          <Collapsable collapsed={!isExpanded}>
            <BlockContent>{children}</BlockContent>
          </Collapsable>
        </Block>
        <Header onClick={e => this.handleToggle(e)} role="switch" aria-checked={isExpanded}>
          {isExpanded ? closeText : openText}
          <Icon left aria-hidden>
            {isExpanded ? <ArrowUp src={Arrow} alt="Arrow Up" /> : <ArrowDown src={Arrow} alt="Arrow Down" />}
          </Icon>
        </Header>
      </Container>
    );
  }
}

// Proptypes
CollapsibleTextArea.propTypes = {
  openText: PropTypes.string.isRequired,
  closeText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.node,
};

CollapsibleTextArea.defaultProps = {
  text: undefined,
};

// Exports
export default CollapsibleTextArea;
