// Import Node Modules
import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';

// Import Default Prop-Types
import DefaultPropTypes from './DefaultPropTypes';

// Import Components
import { FullWrapper } from '../components/CommonComponents/index';
import Footer from '../components/LayoutComponents/FooterComponent/index';
import Navigation from '../components/LayoutComponents/NavBarComponent/index';
import DesktopTools from './components/DesktopTools/index';
import { CookieProvider } from '../components/CommonComponents/CookieConsent/Context';

// Import LoginContext
import { Provider as LoginProvider } from '../Context/loginContext';

// Import GlobalStyles
import GlobalStyle from '../cssGuides/GlobalStyles/index';

// Import Themes
import themes from '../theme/index';

import { isProduction } from "./helperFunctions";

// Variables
const sizeWrapper = '100%';

const disallowedIframeDomains = [
  'www.sampension.dk',
  'sampension.dk',
  'www.isp.dk',
  'isp.dk',
  'www.arkitektpension.dk',
  'arkitektpension.dk',
  'www.pjdpension.dk',
  'pjdpension.dk',
  'web-dev.sampension.dk',
  'web-test.sampension.dk',
  'web-staging.sampension.dk',
];

const links = [
  {
    rel: 'stylesheet',
    type: 'text/css',
    href: 'https://fonts.googleapis.com/css?family=Montserrat:300:400,500,700',
  },
];

if (
  global.top
  && global.top.location
  && global.top !== global.self
) {
  try {
    if (disallowedIframeDomains.includes(global.top.location.hostname) && !global.top.location.pathname.startsWith('/Composite/')) {
      global.top.location.href = global.location.href;
    }
  } catch (err) {
    global.top.postMessage({
      type: 'external-navigation',
      payload: {
        href: global.location.href,
      },
    });
    console.error('Could not break iframe, trying post message API'); // eslint-disable-line
  }
}

// Common stuff
export class CustomLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFirstTab = this.handleFirstTab.bind(this);
    this.handleMouseDownOnce = this.handleMouseDownOnce.bind(this);
  }

  componentDidMount() {
    global.addEventListener('keydown', this.handleFirstTab);
  }

  componentWillUnmount() {
    global.removeEventListener('keydown', this.handleFirstTab);
    global.removeEventListener('mousedown', this.handleMouseDownOnce);
  }

  handleFirstTab(e) {
    if (e.keyCode === 9) {
      global.document.body.classList.add('user-is-tabbing');
      global.removeEventListener('keydown', this.handleFirstTab);
      global.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  }

  handleMouseDownOnce() {
    global.document.body.classList.remove('user-is-tabbing');
    global.removeEventListener('mousedown', this.handleMouseDownOnce);
    global.addEventListener('keydown', this.handleFirstTab);
  }

  render() {
    const { data, children } = this.props;
    const theme = themes[data.theme || process.env.GATSBY_THEME || 'sampension'];
    const ogMeta = [{
      property: 'og:title',
      content: data.pageTitle,
    }, {
      property: 'og:type',
      content: 'website',
    }];
    if (data.pageDescription) {
      ogMeta.push({
        property: 'og:description',
        content: data.pageDescription,
      });
    }
    ogMeta.push({
      property: 'og:image',
      content: data.ogData && data.ogData.image ? data.ogData.image : `https://${theme.Domaine}${theme.SquareLogo}`,
    });
    return (
      <ThemeProvider theme={theme}>
        <LoginProvider
          // origin="https://classic.sampension.dk"
          // logoutUrl="https://classic.sampension.dk/Forside/log-ind/din-pension/Log-ud"
          origin={theme.Origin}
          logoutUrl={theme.logoutUrl}
          homeUrl={theme.homeUrl}
          profileUrl={theme.profileUrl}
          onLogout={() => {
            // NOTE: we reload the page to do a full state refresh
            global.location.href = theme.StartUrl
          }}
        >
          <CookieProvider>
            <div
              style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              className="application"
            >
              <Helmet
                title={data.pageTitle}
                meta={[
                  {
                    name: 'description',
                    content: `${data.pageDescription}`,
                    class: 'swiftype',
                    'data-type': 'text',
                  },
                  {
                    name: 'google-site-verification',
                    content: 'Jg-W60yw35edqRV6ErrZzbZrhxbEXUaAfA3IaONy-4c',
                  },
                  ...ogMeta,
                ]}
                link={links}
              >
                <script
                  id="CookieConsent"
                  src="https://policy.app.cookieinformation.com/uc.js"
                  data-culture="DA"
                  type="text/javascript"
                />
                {isProduction() && <script>{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NXNQCPK')"}</script>}  
              </Helmet>
              <GlobalStyle />
              <Navigation
                size={sizeWrapper}
                page={data.level_1_Id}
                menu={data.nav}
                megaMenu={data.megaMenu}
                isLoaded={data.level_2_Id}
              />
              <div style={{ width: '100%', paddingBottom: '20px' }}>
                <FullWrapper>
                  {children}
                  {data.desktopTools && <DesktopTools data={data.desktopTools} />}
                  <Footer footer={data.footer} size={sizeWrapper} />
                </FullWrapper>
              </div>
              {/* <BottomBorder /> */}
            </div>
          </CookieProvider>
        </LoginProvider>
      </ThemeProvider>
    );
  }
}

// Proptypes
CustomLayout.propTypes = DefaultPropTypes;

export default CustomLayout;
