// Component Name : LightBox
// C1 CMS Name : LightBox

// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';
import { media } from '../../../cssGuides/MediaQuerys';

// Styled Components

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0, 1);
  transition: opacity 150ms ease-in-out;
  transform-origin: right;
  padding: 0.5em;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: 0;
  &:target {
    transform: scale(1, 1);
    transform-origin: left;
    opacity: 1;
  }
  ${media.tabletP`
    padding: 2em;
  `};
`;

const Content = styled.div`

  padding: 20px;
  background: ${ColorGuide.lightGray};
  position: relative;
  border-radius: 7px;
  height: 80%;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5), 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  color: black;
  width:100%;
  ${media.tabletP`
    width: 80%;
    max-width: 1280px;
  `};
`;

const Close = styled.a`
  float: right;
  border: black 2px solid;
  display: flex;
  background: ${ColorGuide.white};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 35px;
  width: 30px;
  height: 30px;
  &:after {
    margin-left:3px;
    content: '+';
    color: black;
    transform: rotate(45deg);
  }
  ${media.tabletP`
    width: 50px;
    height: 50px;
  `};
  ${media.tabletL`

  `};
  ${media.desktop`

  `};
`;

// Class
const LightBox = (props) => {
  const { hashtag, content } = props;

  return (
    <Container id={hashtag} role="none">
      <Content
        role="dialog"
        aria-label="Modal"
        aria-modal
      >
        <Close href="#close" className="close" role="button" aria-label="Close Modal" />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
    </Container>
  );
};

// Proptypes
LightBox.propTypes = {
  hashtag: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

// Exports
export default LightBox;
