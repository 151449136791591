// Import Node Modules
import styled from 'styled-components';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Styled Components
export const Container = styled.section`
  background-color: ${ColorGuide.aqua};
  text-align: center;
  padding: 20px 20px;

  ${media.tabletP`

  `}
  ${media.tabletL`

  `}
  ${media.desktop`
    padding: 90px 0;
  `}
`;
export const Header = styled.h2`
  color: ${ColorGuide.white};
  ${media.desktop`
    font-size:50px;
  `};
`;

export const Manchet = styled.p`
  text-align: center;
  color: ${ColorGuide.white};
  margin-bottom: 20px;
`;

export const InputBox = styled.input`
  width: 90%;
  ${media.tabletP`
    max-width: 372px;
  `};
`;

export const InputBtn = styled.input`
  background-color: ${ColorGuide.darkGray} !important;
  width: 136px;
`;
