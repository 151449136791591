import { css } from 'styled-components';

const globalFocus = css`
  body:not(.user-is-tabbing) *:focus {
    outline: none;
  }

  body.user-is-tabbing *:focus {
    outline: 2px solid #7aacfe !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
  }
`;

export default globalFocus;
