// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import { Columns } from '../../CommonComponents';
import { LevelOneHeader, Content, Header } from './styledMobile';

import Collapsible from './Collap';

class Mobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderContent(body) {
    const { classType, thead } = this.props;
    let defaultClass = 'left';
    switch (classType) {
      case 'table_layout_left':
        defaultClass = 'left';
        break;
      case 'table_layout_center':
        defaultClass = 'center';
        break;
      case 'table_layout_right':
        defaultClass = 'right';
        break;
      default:
        defaultClass = 'left';
    }

    return (
      <div>
        {thead[0].tr
          .map((th, i) => ({
            id: i,
            th: th.value,
            tr: body.tr[i].value,
          }))
          .slice(1)
          .map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Content key={index}>
              <Columns columns={2} minWidth="300px">
                <div dangerouslySetInnerHTML={{ __html: content.th }} />
                <div
                  style={{ textAlign: defaultClass }}
                  dangerouslySetInnerHTML={{ __html: content.tr }}
                />
              </Columns>
            </Content>
          ))}
      </div>
    );
  }

  render() {
    const { thead, insideCollapsible, tbody } = this.props;

    let emptyTable = false;

    // if (this.props.thead.tr && this.props.thead.tr.length > 0) {
    if (thead.length > 0 && thead[0].tr.length > 0) {
      emptyTable = false; // ok
    } else {
      emptyTable = true;
    }

    return emptyTable ? null : (
      <div style={{ marginBottom: '20px' }}>
        <LevelOneHeader
          dangerouslySetInnerHTML={{
            __html: thead[0].tr[0].value,
          }}
        />

        {insideCollapsible}

        {tbody.map((body, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {insideCollapsible ? (
              <div>
                <Header>{body.tr[0].value}</Header>
                {this.renderContent(body)}
              </div>
            ) : (
              <Collapsible title={body.tr[0].value}>
                {this.renderContent(body)}
              </Collapsible>
            )}
          </div>
        ))}
      </div>
    );
  }
}

// Proptypes
Mobile.propTypes = {
  classType: PropTypes.string,
  thead: PropTypes.arrayOf(
    PropTypes.shape({
      classType: PropTypes.string,
      tr: PropTypes.arrayOf(
        PropTypes.shape({
          classType: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  tbody: PropTypes.arrayOf(
    PropTypes.shape({
      classType: PropTypes.string,
      tr: PropTypes.arrayOf(
        PropTypes.shape({
          classType: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  insideCollapsible: PropTypes.bool,
};

Mobile.defaultProps = {
  insideCollapsible: undefined,
  classType: '',
};

export default Mobile;
