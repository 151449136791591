// Import Node Modueles
import styled from 'styled-components';

// Import CSS Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';
import { media } from '../../../cssGuides/MediaQuerys';

export const ToolsContainer = styled.div`
  position: ${props => (props.pos === 'check' ? 'relative' : 'fixed')};
  bottom: 20px;
  right: 0px;
  z-index: 3000;
  pointer-events: none;
  display: none;
  ${media.tabletP`
    display: block;
  `};
`;

export const Container = styled.div`
  width: 590px;
  height: 350px;
  overflow: hidden;
  box-shadow: ${ColorGuide.shadow};
  background-color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 100px 20px;
  position: relative;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: all 0.3s;
  top: ${props => (props.visible ? '0px' : '100px')};
`;

export const Wrapper = styled.div`
  width: 2200px;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;
`;

export const Content = styled.div`
  width: 590px;
  height: 350px;
  text-align: center;
  position: relative;
  a {
    pointer-events: auto;
  }
`;

export const CloseBtn = styled.div`
  width: 40px;
  height: 40px;
  pointer-events: auto;
  position: absolute;
  top: -85px;
  right: 35px;
  background-color: ${props => props.theme.PrimaryColor};
  color: ${ColorGuide.white};
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

// NavLinks

export const NavLinks = styled.div`
  position: relative;
  opacity: ${({ menu }) => (menu ? '1' : '0')};
  transition: all 0.3s;
  text-align: right;
  width: 590px;
  ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    box-shadow: ${ColorGuide.shadow};
    border-top-left-radius: ${({ visible }) => (visible ? '0' : '4')}px;
    border-bottom-left-radius: 4px;
  }

  li {
    float: left;
    pointer-events: auto;
  }

  ul li {
    padding-left: 0px;
    &:before {
      content: '';
    }

    cursor: pointer;

    font-size: 12px;
    width: 90px;
    height: 90px;
    background-color: ${ColorGuide.aqua};
    border-right: ${ColorGuide.white} 1px solid;
    overflow: hidden;
    &:first-child {
      border-top-left-radius: ${({ visible }) => (visible ? '0' : '4')}px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-right: none;
    }
  }
  ul li.active {
    color: red;
  }
  ul li img {
    pointer-events: none;

    margin-left: 8.4px;
  }
`;

export const BtnContainer = styled.div`
  padding-top: 17px;
  width: 90px;
  height: 90px;
  text-align: center;

  color: ${({ show, module }) => (show === module ? ColorGuide.darkGray : ColorGuide.white)};

  background-color: ${({ show, module, theme }) => (show === module ? ColorGuide.white : theme.PrimaryColor)};

  img {
    width: 30px;
  }
`;

export const Servicemeddelelse = styled.li`
  width: 320px !important;
`;

export const ServicemeddelelseContent = styled.div`
  border: 2px solid ${({ theme }) => theme.PrimaryColor};
  background-color: ${ColorGuide.white};
  padding: 5px 5px 5px 5px;
  text-align: left;
  height: 100%;
  cursor: initial;
  text-indent: 0;
  p {
    margin: 0;
    font-size: 14px;
    text-indent: 0;
  }
`;
