// Import Node Modules
import styled from 'styled-components';

// Media Queries
import { media } from '../../../../cssGuides/MediaQuerys';

// Import Guides
import * as Variables from '../Variables';
import * as ColorGuide from '../../../../cssGuides/colorGuide';

// Components
const Hamburger = styled.span`
  color: ${ColorGuide.white};
  position: relative;
  font-size: 30px;
  line-height: 43px;
  height: ${Variables.level1}px;
  top: 0px;
  right: 0px;
  padding-top: 3px;
  display: block;
  width: 50px;

  ${media.tabletL`
    display:none;
  `};
`;

export default Hamburger;
