// Component Name : SearchModule
// C1 CMS Name : SearchModule

// Import Node Modules
import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

// Import Styled-components
import * as Search from './styledcomponents';
import { Spinner } from '../../CommonComponents/index';
import Arrow from '../../../assets/Icons/svg/icon_Arrow_Right_Blue.svg';

const getUrl = (url) => {
  const elm = global.document.createElement('a');
  elm.href = url;
  return elm;
};

const createArray = (count, value) => {
  const result = [];
  for (let i = 0; i < count; i += 1) {
    result[i] = value;
  }
  return result;
};

// Class : SearchModule
const debounce = (func, delay) => {
  let inDebounce;
  return (...args) => {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const getHash = () => {
  const { hash } = global.location;
  return hash
    .slice(1)
    .split('&')
    .reduce((output, parts) => {
      const [name, value] = parts.split('=');
      return {
        ...output,
        [name]: decodeURIComponent(value),
      };
    }, {});
};

class SearchModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      resultCount: 0,
      suggestions: [],
      pages: 1,
      error: undefined,
      page: 1,
      loading: false,
      currentInput: '',
      currentSearch: '',
    };
    this.renderResult = this.renderResult.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.updateResults = this.updateResults.bind(this);
    this.updateSuggestions = this.updateSuggestions.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.debounchedUpdateSuggestions = debounce(this.updateSuggestions, 500);
  }

  componentDidMount() {
    const { searchUrl, page } = getHash();
    if (searchUrl) {
      this.runSearch(searchUrl, page);
    }
  }

  handleInputChanged(evt) {
    const { value } = evt.target;
    const newValue = value;
    this.setState(
      {
        currentInput: newValue,
        error: undefined,
      },
      () => {
        this.debounchedUpdateSuggestions(newValue);
      },
    );
  }

  handleKeyDown(evt) {
    // console.log(evt.keyCode);
    if (evt.keyCode === 13) {
      this.updateResults();
    }
  }

  updateSuggestions(q) {
    this.search(q, undefined, 'suggest')
      .then((result) => {
        this.setState({
          suggestions: result.records,
        });
      })
      .catch((err) => {
        this.setState({
          results: [],
          error: err,
        });
      });
  }

  updateResults() {
    const { currentInput: q } = this.state;
    global.history.replaceState(
      null,
      null,
      `#searchUrl=${encodeURIComponent(q)}`,
    );
    this.runSearch(q, 1);
  }

  goToPage(pageNumber) {
    const { currentSearch: q } = this.state;
    this.runSearch(q, pageNumber);
    global.history.replaceState(
      null,
      null,
      `#searchUrl=${encodeURIComponent(q)}&page=${pageNumber}`,
    );
  }

  runSearch(q, page = 1) {
    this.setState(
      {
        suggestions: [],
        loading: true,
        currentInput: '',
        currentSearch: q,
      },
      () => {
        this.search(q, page)
          .then((result) => {
            this.setState({
              page: parseInt(page, 10) || 1,
              loading: false,
              results: result.records,
              pages: result.info.num_pages,
              resultCount: result.info.total_result_count,
            });
          })
          .catch((err) => {
            this.setState({
              results: [],
              error: err,
            });
          });
      },
    );
  }

  search(q, page, type = 'search') {
    const { documentType = 'page', theme } = this.props;
    return new Promise((resolve, reject) => {
      const xhr = new global.XMLHttpRequest();
      xhr.open(
        'POST',
        `https://search-api.swiftype.com/api/v1/public/engines/${type}.json`,
      );
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve({
          records: response.records[documentType],
          info: response.info[documentType],
        });
      });
      xhr.addEventListener('error', (err) => {
        reject(err);
      });
      xhr.send(
        JSON.stringify({
          q,
          engine_key: theme.EngineKey,
          page,
        }),
      );
    });
  }

  scrollToTop() {
    const c = global.document.documentElement.scrollTop
      || global.document.body.scrollTop;
    if (c > 0) {
      global.requestAnimationFrame(this.scrollToTop);
      global.scrollTo(0, c - c / 16);
    }
  }

  renderResult(document) {
    // console.log('URL : ', document.url);
    return (
      <Search.ResultItem
        key={document.id}
        role="option"
        aria-label="Search Result"
      >
        <Link to={getUrl(document.url).pathname}>
          {/* <div
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(
              document.highlight.title || document.title
            ),
          }}
        /> */}
          <Search.Arrow src={Arrow} alt="Arrow Right" />
          <h3
            style={{ marginBottom: '10px' }}
            dangerouslySetInnerHTML={{
              // __html:
              //   document.highlight.sections
              //   || document.sections
              //   || document.title,
              __html: decodeURI(document.title),
            }}
          />
          <div
            style={{ marginBottom: '10px' }}
            dangerouslySetInnerHTML={{
              // __html: document.highlight.body || document.body,
              __html: document.description,
            }}
          />
        </Link>
      </Search.ResultItem>
    );
  }

  renderSuggestion(document) {
    return (
      <Search.SuggestionItem key={document.id}>
        <Link to={getUrl(document.url).pathname}>
          <span
            dangerouslySetInnerHTML={{
              // __html:
              //   document.highlight.sections
              //   || document.sections
              //   || document.title,
              __html: decodeURI(document.title),
            }}
          />
        </Link>
      </Search.SuggestionItem>
    );
  }

  renderPaging(currentPage, numberOfPages, goToPage) {
    const { currentSearch } = this.state;
    return currentSearch !== '' ? (
      <Search.PageContainer role="region" aria-label="Pagination">
        {createArray(numberOfPages, '')
          .map((a, i) => i + 1)
          .map((i) => {
            return i <= currentPage - 3 || i >= currentPage + 3 ? null : (
              <Search.PageButton
                role="button"
                aria-label={`Page : ${i}`}
                key={i}
                onClick={() => {
                  goToPage(i);
                  this.scrollToTop();
                }}
                show={currentPage === i}
              >
                {currentPage === i ? `${i}` : i}
              </Search.PageButton>
            );
          })}
      </Search.PageContainer>
    ) : null;
  }

  renderLoading() {
    return (
      <Search.SpinnerContainer>
        <Spinner />
      </Search.SpinnerContainer>
    );
  }

  rendercurrentSearch(contentText, results) {
    const { currentSearch } = this.state;
    return currentSearch === '' ? (
      <div
        style={{ minHeight: '225px' }}
        dangerouslySetInnerHTML={{ __html: contentText }}
      />
    ) : (
      <div>
        {/* results: */}
        {results.map(this.renderResult)}
      </div>
    );
  }

  render() {
    const {
      results,
      resultCount,
      currentInput,
      currentSearch,
      error,
      suggestions,
      loading,
      page,
      pages,
    } = this.state;

    const { contentText, theme } = this.props;

    const showResult = () => {
      if (currentSearch !== '') {
        if (resultCount === 0) {
          return <div>ingen resultater</div>;
        }
        return (
          <div>
            Viser fra {page * 20 - 19} til {page * 20 - 19 + results.length - 1}{' '}
            af {resultCount} søgeresultater
          </div>
        );
      }
      return null;
    };

    return (
      <Search.OuterContainer role="region" aria-label="Search Page">
        <Search.Container role="none">
          <label htmlFor="Search">
            <center>
              <h2>Søg på {theme.Domaine}</h2>
            </center>
            <input
              value={currentInput}
              // placeholder={currentSearch || 'Søg her...'}
              placeholder="Søg her..."
              onChange={this.handleInputChanged}
              onKeyDown={this.handleKeyDown}
              id="Search"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </label>
          {/* <button onClick={this.updateResults}>Søg</button> */}
        </Search.Container>
        <Search.ShowCount>{showResult()}</Search.ShowCount>
        {error && <div>{error.toString()}</div>}
        {currentInput && (
          <Search.SuggestionsContainer>
            {/* suggestions: */}
            {suggestions.map(this.renderSuggestion)}
          </Search.SuggestionsContainer>
        )}
        {/* {!loading ? (

          <div>results:
            {results.map(this.renderResult)}
          </div>
        ) : this.renderLoading()} */}

        <Search.ContentContainer>
          {!loading
            ? this.rendercurrentSearch(contentText, results)
            : this.renderLoading()}
        </Search.ContentContainer>
        {this.renderPaging(page, pages, this.goToPage)}
      </Search.OuterContainer>
    );
  }
}

// Proptypes
SearchModule.propTypes = {
  documentType: PropTypes.string,
  contentText: PropTypes.string,
  theme: PropTypes.string.isRequired,
};

SearchModule.defaultProps = {
  documentType: 'page',
  contentText: undefined,
};

// Exports
export default withTheme(SearchModule);
