// Import Node Modules
import { css } from 'styled-components';

// Import Variables
import * as ColorGuide from '../colorGuide';

// CSS Radio buttons

const globalRadioButton = css`
  input[type='radio'] {
    font-size: 16px;
    margin: 0 10px 10px 0;
    vertical-align: 2px;
    border: none;
    &:after {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -2px;
      left: -2px;
      position: relative;
      background-color: ${ColorGuide.white};
      content: '';
      display: inline-block;
      visibility: visible;
      border: 3px solid ${ColorGuide.white};
    }
    &:checked {
      &:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: ${props => props.theme.PrimaryColor};
        content: '';
        display: inline-block;
        visibility: visible;
        border: 3px solid ${ColorGuide.white};
      }
    }
  }
`;

export default globalRadioButton;
