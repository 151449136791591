// Import Node Modules
import React from 'react';

// Import Components
import Overlay from './Overlay';

// Functions
// eslint-disable-next-line consistent-return
export const GetDisplayHeader = (data) => {
  if (data.bannerImage != null) {
    return -130;
  }
  if (data.bannerContent == null && data.bannerImage != null) {
    return -200;
  }
};

export const RenderContent = (data, isMobile) => {
  const { mobile, bannerContent, bannerImage } = data;
  return (
    <div>
      {isMobile ? (
        <Overlay
          image={mobile || bannerImage}
          content={bannerContent}
          bottomMargin="85px"
          displayMobile={mobile != null}
          mobile
        />
      ) : (
        <Overlay
          image={bannerImage}
          content={bannerContent}
          bottomMargin="85px"
          mobile={false}
        />
      )}
    </div>
  );
};

export const isMobileOnClient = () => {
  // eslint-disable-next-line no-undef
  return typeof window !== 'undefined' && window.innerWidth < 768;
};

export const isProduction = () => {
  // eslint-disable-next-line no-undef
  switch (window.location.hostname) {
    case 'www.sampension.dk':
    case 'www.isp.dk':
    case 'www.pjdpension.dk':
    case 'www.arkitektpension.dk':
      return true;
    default:
      return false;
  }
}