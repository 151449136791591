// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Common Components
import { FullWrapperSide, Wrapper } from '../../CommonComponents';

import { isMobileOnClient } from '../../../template/helperFunctions';

// Import Components
import MainMenu from './Components/MainMenu';
import MainMenuBar from './Components/MainMenuBar';
import Desktop from './Components/Desktop';
import Mobile from './Components/Mobile';
import MegaMenu from './Components/MegaMenu';
import SideBar from './Components/SideBar';

// Import Styled-components
import {
  NavBarContainer,
  NavBar,
  ClearSpaceBehindNav,
  ColorBar,
} from './StyledComponents/Navigation';

// Import Variables
// import * as Variables from './Variables';

// Navigation Class
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: true,
      navHeight: this.getNavHeight(),
      // navHeight: 0,
      collapseNav: false,
      MegaMenuVisible: false,
      ActiveMegaMenu: '',
      ActiveMenu: props.page,
      isLoaded: props.isLoaded,
      open: [],
    };
    this.prevScrollpos = global.scrollY;
    this.handleNavResize = this.handleNavResize.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.changeMenu = this.changeMenu.bind(this);
    this.changeMegaMenu = this.changeMegaMenu.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.changeMobileMenu = this.changeMobileMenu.bind(this);
  }

  componentDidMount() {
    this.handleNavResize();
    global.addEventListener('resize', this.handleNavResize);
    global.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
    global.removeEventListener('scroll', this.onScroll);
  }

  handleNavResize() {
    this.setState({
      navHeight: this.getNavHeight(),
    });
  }

  onScroll() {
    const {
      open,
      MegaMenuVisible,
      navHeight,
      showNav,
    } = this.state;

    const anyOpen = open.length > 0;

    if (anyOpen === false) {
      const currentScrollPos = Math.max(global.scrollY, 0);
      const showNavVar = this.prevScrollpos > currentScrollPos || currentScrollPos === 0;
      if (currentScrollPos >= navHeight) {
        if (showNav !== showNavVar) {
          if (MegaMenuVisible === false) {
            this.setState({
              showNav: showNavVar,
            });
          }
        }
        this.prevScrollpos = currentScrollPos;
      } else if (!showNav) {
        this.setState({
          showNav: true,
        });
      }
    }
  }

  getNavHeight() {
    return isMobileOnClient() ? 50 : 150;
  }

  // Menu Functions
  changeMenu(x) {
    this.setState({
      ActiveMenu: x,
      ActiveMegaMenu: '',
      MegaMenuVisible: false,
    });
  }

  // MegaMenu functions
  changeMegaMenu(x) {
    const { MegaMenuVisible, ActiveMegaMenu } = this.state;
    if (MegaMenuVisible === false) {
      this.setState({
        MegaMenuVisible: true,
      });
    }
    if (x === ActiveMegaMenu) {
      this.setState({
        MegaMenuVisible: !MegaMenuVisible,
        ActiveMegaMenu: '',
      });
    } else {
      this.setState({
        ActiveMegaMenu: x,
      });
    }
  }

  // Sidebar Navigation functions
  openNav(id) {
    const { open } = this.state;
    this.setState({
      open: [...open, id],
    });
    global.document.body.style.overflow = 'hidden';
  }

  closeNav(id) {
    const { open } = this.state;
    this.setState({
      open: open.filter(s => s !== id),
    });
  }

  closeAll() {
    this.setState({
      open: [],
    });
    global.document.body.style.overflow = 'auto';
  }

  changeMobileMenu(x) {
    this.setState({
      ActiveMenu: x,
    });
  }

  // Render
  render() {
    const {
      menu,
      megaMenu,
      size,
      loggedIn,
    } = this.props;

    const {
      showNav,
      collapseNav,
      navHeight,
      ActiveMenu,
      ActiveMegaMenu,
      isLoaded,
      open,
      MegaMenuVisible,
    } = this.state;

    return (
      <>
        <NavBarContainer style={{ top: showNav ? '0' : '-158px' }}>
          <FullWrapperSide>
            <NavBar collapse={collapseNav}>
              <MainMenu
                showNav={showNav}
                ActiveMenu={ActiveMenu}
                navHeight={navHeight}
                collapseNav={collapseNav}
                menu={menu}
                changeMenu={this.changeMenu}
                size={size}
              />
              <Wrapper minWidth={size}>
                <MainMenuBar
                  navHeight={navHeight}
                  collapse={collapseNav}
                  menu={menu}
                  loggedIn={loggedIn}
                >
                  {navHeight === 150 ? (
                    <Desktop
                      ActiveMenu={ActiveMenu}
                      ActiveMegaMenu={ActiveMegaMenu}
                      isLoaded={isLoaded}
                      menu={menu}
                      changeMegaMenu={this.changeMegaMenu}
                    />
                  )
                    : (
                      <Mobile
                        open={open}
                        openNav={this.openNav}
                        closeAll={this.closeAll}
                      />
                    )
                  }
                </MainMenuBar>
              </Wrapper>
            </NavBar>
            <ColorBar />
            {navHeight === 150
            && (
              <MegaMenu
                changeMegaMenu={this.changeMegaMenu}
                size={size}
                MegaMenuVisible={MegaMenuVisible}
                ActiveMegaMenu={ActiveMegaMenu}
                megaMenu={megaMenu}
              />
            )}
          </FullWrapperSide>
        </NavBarContainer>

        <SideBar
          open={open}
          ActiveMenu={ActiveMenu}
          menu={menu}
          megaMenu={megaMenu}
          closeAll={this.closeAll}
          closeNav={this.closeNav}
          openNav={this.openNav}
          changeMobileMenu={this.changeMobileMenu}
        />

        <ClearSpaceBehindNav
          collapse={collapseNav}
        />
      </>
    );
  }
}

// Proptypes
Navigation.propTypes = {
  size: PropTypes.string.isRequired,
  loggedIn: PropTypes.string,
  isLoaded: PropTypes.string,
  page: PropTypes.string,
  menu: PropTypes.shape({
    searchUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    menus: PropTypes.shape({
      subMenus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        display: PropTypes.string,
        internal: PropTypes.bool,
        menuItems: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })),
      })).isRequired,
    }).isRequired,
  }).isRequired,
  megaMenu: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

Navigation.defaultProps = {
  page: undefined,
  loggedIn: undefined,
  isLoaded: undefined,
};

export default Navigation;
