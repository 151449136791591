// Component Name : Beta
// C1 CMS Name : Beta

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Import Guides
import * as ColorGuide from '../../../cssGuides/colorGuide';

import { isMobileOnClient } from '../../../template/helperFunctions';


// Import Images
import beta from '../../../assets/Images/Mac_beta.png';
import betaMobil from '../../../assets/Images/Iphone_beta.png';

// Import Components
import {
  Container,
  Image,
  Image2,
  Content,
  TextBlock,
  TextContainer,
  Inner,
} from './styledcomponents';

import Modal from '../Modal';

import { Columns } from '../../CommonComponents/index';


class Beta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show2: false,
      show3: false,
      name: '',
      email: '',
      select: 'Iphone',
      mobile: isMobileOnClient(),
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal2 = this.showModal2.bind(this);
    this.hideModal2 = this.hideModal2.bind(this);
    this.showModal3 = this.showModal3.bind(this);
    this.hideModal3 = this.hideModal3.bind(this);
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleNavResize);
    this.handleNavResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value,
    });
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleSelectChange(event) {
    this.setState({ select: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, select } = this.state;
    axios.post('https://api-staging.sampension.dk/feedback/v1/betasignup', {
      Name: name,
      Email: email,
      Phone: select,
    });
    this.showModal2();
  }

  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({ show: false });
  }

  showModal2() {
    this.setState({
      show: false,
      show2: true,
    });
  }

  hideModal2() {
    this.setState({ show2: false });
  }

  showModal3() {
    this.setState({
      show3: true,
    });
  }

  hideModal3() {
    this.setState({ show3: false });
  }

  renderContent() {
    const {
      header,
      getAppBtn,
      nemIdBtn,
      nemidBtnText,
    } = this.props;
    const { mobile } = this.state;
    return (
      <div>
        <h2>{header}</h2>
        {mobile === true ? (
          <>
            <button
              type="button"
              onClick={this.showModal}
              className="linkBtn"
              style={{ width: '310px', margin: '9px 0' }}
            >
              {getAppBtn}
            </button>
            <a href={process.env.GATSBY_WEB_APP_URL || '/classic'} className="secondaryBtn" style={{ width: '310px', margin: '9px 0' }}>
              {nemIdBtn}
            </a>
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={this.showModal3}
              className="linkBtn"
              style={{ width: '310px', margin: '9px 0' }}
            >
              {nemidBtnText}
            </button>
            <button
              type="button"
              onClick={this.showModal}
              className="secondaryBtn"
              style={{ width: '310px', margin: '9px 0' }}
            >
              {getAppBtn}
            </button>
          </>
        )}
      </div>
    );
  }

  render() {
    const {
      textBlock,
      modalSignUp,
      modalFinish,
    } = this.props;

    const {
      show,
      show2,
      show3,
      name,
      select,
      email,
      mobile,
    } = this.state;

    return (
      <div>
        <Container>
          {mobile === true ? (
            <Columns columns={2} minWidth="768px">
              <Content role="group" aria-label="Wide Card">
                <center>{this.renderContent()}</center>
              </Content>
              <div
                style={{
                  backgroundColor: 'white',
                  height: 'auto',
                  textAlign: 'center',
                }}
              >
                <Image2 src={betaMobil} role="img" />
              </div>
            </Columns>
          ) : (
            <Columns columns={2} minWidth="768px">
              <div>
                <Image src={beta} role="img" />
                <Image2 src={betaMobil} role="img" />
              </div>
              <Content>
                <Inner>{this.renderContent()}</Inner>
                <br />
                <br />
                <br />
                <br />
              </Content>
            </Columns>
          )}

          <TextContainer>
            <TextBlock dangerouslySetInnerHTML={{ __html: textBlock }} />
            <br />
          </TextContainer>

          <Modal
            posted={false}
            show={show}
            handleClose={this.hideModal}
          >
            <div dangerouslySetInnerHTML={{ __html: modalSignUp }} />
            <form onSubmit={this.handleSubmit}>
              <input
                placeholder="Navn"
                type="text"
                value={name}
                onChange={this.handleNameChange}
                required
                style={{ backgroundColor: ColorGuide.lightGray }}
              />
              <br />
              <br />
              <input
                placeholder="E-mail"
                type="email"
                value={email}
                onChange={this.handleEmailChange}
                required
                style={{ backgroundColor: ColorGuide.lightGray }}
              />
              <br />
              <br />
              <select
                value={select}
                onChange={this.handleSelectChange}
                style={{ backgroundColor: ColorGuide.lightGray }}
              >
                <option value="Iphone">Iphone</option>
                <option value="Android">Android</option>
              </select>

              <br />
              <br />
              <br />

              <input type="submit" value="Send" />
            </form>
          </Modal>

          <Modal
            posted
            show={show2}
            handleClose={this.hideModal2}
          >
            <div
              dangerouslySetInnerHTML={{ __html: modalFinish }}
              style={{ margin: '130px auto' }}
            />
          </Modal>
          <Modal
            posted={false}
            show={show3}
            handleClose={this.hideModal3}
          >
            <div style={{ textAlign: 'left' }}>
              <h3 style={{ textAlign: 'center' }}>Vi gør opmærksom på:</h3>
              <br />
              <p className="small">
                Det nye login univers er stadig under udvikling. Derfor vil du
                endnu ikke kunne finde alle dine velkendte
                selvbetjeningsmuligheder.
                <br />
                Eksempelvis kan du i øjeblikket ikke benytte følgende:
              </p>
              <ul style={{ marginBottom: '14px', fontSize: '14px' }}>
                <li>Depotoverførsel</li>
                <li>Begunstigelse</li>
                <li>Privat indskud</li>
              </ul>
              <p>
                Ønsker du at benytte disse funktioner, skal du bruge det gamle
                login univers.
              </p>
              <center>
                <a
                  // eslint-disable-next-line no-underscore-dangle
                  href={process.env.__GATSBY_WEB_APP_URL || 'https://login.sampension.dk'}
                  className="linkBtn"
                  style={{ width: '310px', margin: '9px 0' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fortsæt til nyt login univers
                </a>

                <a
                  href={process.env.GATSBY_WEB_APP_URL || '/classic'}
                  className="secondaryBtn"
                  style={{ width: '310px', margin: '9px 0' }}
                >
                  Gammelt log ind
                </a>
              </center>
            </div>
          </Modal>
        </Container>
      </div>
    );
  }
}

Beta.propTypes = {
  header: PropTypes.string.isRequired,
  getAppBtn: PropTypes.string.isRequired,
  nemIdBtn: PropTypes.string.isRequired,
  nemidBtnText: PropTypes.string.isRequired,
  textBlock: PropTypes.string.isRequired,
  modalSignUp: PropTypes.string.isRequired,
  modalFinish: PropTypes.string.isRequired,
};

// exports
export default Beta;
