// Component Name : CollapseableFAQ
// C1 CMS Name : FAQ

// Import Node Modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Collapsable from '../../CommonComponents/Collapsible/Collapsable';

// Import Images
import Arrow from '../../../assets/Icons/svg/icon_Arrow_Down_Blue.svg';

// Import StyledComponents
import {
  ArrowUp,
  ArrowDown,
  Icon,
  Container,
  Header,
  Block,
  BlockContent,
  CollapseContainer,
  OutsideHeader,
} from './styledcomponents';

// Classes
class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle() {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  render() {
    const { header, children } = this.props;
    const { isExpanded } = this.state;

    return (
      <Container role="article" aria-label={header} onClick={() => this.handleToggle()}>
        <Header>
          {header}
          <Icon role="switch" aria-checked={isExpanded}>
            {isExpanded ? (
              <ArrowUp src={Arrow} aria-hidden alt="Arrow Up" />
            ) : (
              <ArrowDown src={Arrow} aria-hidden alt="Arrow Down" />
            )}
          </Icon>
        </Header>
        <Block role="article" aria-label="Answer">
          <Collapsable collapsed={!isExpanded}>
            <BlockContent>{children}</BlockContent>
          </Collapsable>
        </Block>
      </Container>
    );
  }
}

const CollapseableFAQ = (props) => { // eslint-disable-line react/no-multi-comp
  const { header, faqs } = props;
  return (
    <CollapseContainer role="group" aria-label="Frequently asked questions">
      <OutsideHeader role="heading">{header}</OutsideHeader>
      {faqs.map(items => (
        <Collapsible
          key={items.id}
          id={items.id}
          header={items.header}
        >
          <div dangerouslySetInnerHTML={{ __html: items.text }} />
        </Collapsible>
      ))}
    </CollapseContainer>
  );
};
// Proptypes
Collapsible.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

CollapseableFAQ.propTypes = {
  header: PropTypes.string.isRequired,
  faqs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

// Exports
export default CollapseableFAQ;
