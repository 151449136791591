// Import Node Modules
import React from 'react';

// Import Default Prop-Types
import DefaultPropTypes from './DefaultPropTypes';

// Import Components
import { CustomLayout } from './CustomLayout';

// Import Helper Functrion
import { GetDisplayHeader, RenderContent, isMobileOnClient } from './helperFunctions';

// Import Styled-Components
import { Template2Wrapper } from './components/Wrappers';
import { Template2Content } from './components';

// Template #2
class Template2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobileOnClient(),
    };
    this.handleNavResize = this.handleNavResize.bind(this);
  }

  componentDidMount() {
    this.handleNavResize();
    global.addEventListener('resize', this.handleNavResize);
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleNavResize);
  }

  handleNavResize() {
    this.setState({
      mobile: isMobileOnClient(),
    });
  }

  render() {
    const { children, data } = this.props;
    const displayHeader = GetDisplayHeader(data);
    const { mobile } = this.state;
    return (
      <CustomLayout data={data}>
        <Template2Wrapper displayHeader={displayHeader} minWidth="100%">
          {data.bannerImage ? RenderContent(data, mobile) : ''}
          <Template2Content displayHeader={displayHeader}>
            {children}
          </Template2Content>
          {data.bottomContent}
        </Template2Wrapper>
      </CustomLayout>
    );
  }
}

// Proptypes
Template2.propTypes = DefaultPropTypes;

export default Template2;
