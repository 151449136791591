// Import Node Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Media Query Variables
import { media } from '../../../cssGuides/MediaQuerys';

// Styled-component
const Container = styled.div`
  width: calc(100% - 10px);
  margin: 0 auto 10px auto;

  // NOTE: workaround for news module full article view
  > hr + h1 {
    margin: 24px;
  }

  ${media.tabletL`
    width: 100%;
  `};
  ${media.tabletP`
    margin: 0 auto 40px auto;
  `}
  ${media.tabletL`
    margin: 0 auto 40px auto;
  `}
  ${media.desktop`
    margin: 0 auto 60px auto;
  `}
`;

// Class
const HtmlRender = (props) => {
  const { html } = props;
  return (
    <Container
      dangerouslySetInnerHTML={{ __html: html }}
      role="article"
      tabIndex="0"
    />
  );
};

// Proptypes
HtmlRender.propTypes = {
  // data: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

// Exports
export default HtmlRender;
