// Component Name : BusinessCard
// C1 CMS Name : Visitkort

// Import node modules
import React from 'react';
import PropTypes from 'prop-types';

// Import component
import Card from './CardContainer/index';
import Business from './CardContent/business';

// Component
const VisitKort = (props) => {
  const {
    image,
    name,
    job,
    tlf,
    email,
  } = props;
  return (
    <Card shadow maxWidth="290px" minHeight="100%">
      <Business
        image={image}
        altText={`${name} Profile Picture`}
        name={name}
        job={job}
        tlf={tlf}
        email={email}
      />
    </Card>
  );
};

// Proptypes
VisitKort.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  tlf: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

VisitKort.defaultProps = {
  image: '',
};
export default VisitKort;
