// Import Node Modules
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { sizes, media } from '../../../../cssGuides/MediaQuerys';
import { VAlignContentCenter } from '../../../CommonComponents/index';

const HEIGHT = 100;

const LogoContainer = styled.div`
  position: relative;
  height: ${(props) => props.imgSize}px;
  padding-left:${(props) => props.theme.LogoLeftMobile};
  ${media.tabletP`
    padding-left:${(props) => props.theme.LogoLeftTabletP};
  `}
  ${media.tabletL`
    padding-left:${(props) => props.theme.LogoLeftTabletL};
  `}
  ${media.desktop`
    padding-left:${(props) => props.theme.LogoLeftDesktop};
  `}
`;

const LogoStyled = styled.img`
  height: ${(props) => props.theme.LogoSizeMobile};
  display: block;
  ${''}
  ${media.tabletP`
    height: ${(props) => props.theme.LogoSizeTabletP};
  `}
  ${media.tabletL`
    height: ${(props) => props.theme.LogoSizeTabletL};
  `}
  ${media.desktop`
    height: ${(props) => props.theme.LogoSizeDesktop};
  `}
`;

export const Logo = withTheme((props) => {
  const { imgSize, theme } = props;
  return (
    <LogoContainer role="img" aria-label="Sampension Logo" imgSize={imgSize}>
      <VAlignContentCenter>
        <Link to={theme.StartUrl}>
          <picture>
            <source media={`(max-width: ${sizes.tabletP - 1}px)`} srcSet={theme.MobileLogo} />
            <source media={`(min-width: ${sizes.tabletP}px)`} srcSet={theme.DesktopLogo} />
            <LogoStyled src={theme.DesktopLogo} alt="Sampension Logo" />
          </picture>
        </Link>
      </VAlignContentCenter>
    </LogoContainer>
  );
});

// Styled-Components - Login/Search

export const LogOut = styled.div`
  background-color: ${props => props.theme.PrimaryColor};
  display: block;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  justify-self: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  width: 70px;
  height: 25px;
  line-height: 25px;
  margin-left: 30px;
`;

export const SearchIcon = styled.div`
  height: ${(props) => props.imgSize}px;

  ${media.tabletP`
    margin-right: 30px;
  `};
`;

export const ImgIcon = styled.img`
  width: 20px;
  margin: 0 0 0 auto;
`;

export const MenuBarContainerDesktop = styled.div`
  transition: all 0.3s;
  height: 50px;
  margin: 0 25px 0 0;
  display: none;
  justify-content: space-between;

  ${media.tabletP`
    display: flex;
    height: ${HEIGHT}px;
  `};
`;


export const MenuBarContainerMobile = styled.div`
  transition: all 0.3s;
  height: 50px;
  display: flex;
  justify-content: space-between;

  ${media.tabletP`
    display: none;
  `};
`;


export const ButtonContainer = styled.div`
  height: ${(props) => props.navHeight};
  display: flex;
`;

export const ChildContainer = styled.div`
  line-height: ${HEIGHT}px;
`;

export const Profile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
`;

export const ProfileName = styled.div`
  margin-left: -3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  color: white;

  ${media.tabletP`
    font-size: 9px;
    max-width: 200px;
  `};
  ${media.tabletL`
    font-size: 10px;
  `};
  ${media.desktop`
    font-size: 13px;
  `};
`;

// Proptypes
Logo.propTypes = {
  imgSize: PropTypes.number.isRequired,
};

export const HamburgerContainer = styled.div`
  padding: 0 10px;
`;
